import {urlService} from "./config"


export async function get(url : string, token : string) : Promise<any> {
     let tokens = localStorage.getItem('token')
    const pageRes = await fetch(urlService + url,  {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokens}`
        },
    });
    const response = await pageRes.json();

    return response
}


export async function post(method: string, body: string, token: string, skipContentType: boolean = false) : Promise<any> {
    let tokens = localStorage.getItem('token')
    const basicHead = {
        Authorization: `Bearer ${tokens}`
    }

    if (!skipContentType) {
        basicHead['Content-Type'] = 'application/json';
    }

    const pageRes = await fetch(urlService + method, {
        method: 'POST',
        headers: basicHead,
        body: body
    });

    try {
        const data = await pageRes.clone().json()
        data.httpStatusCode = pageRes.status;
        return data;
    } catch(err) {
       return await pageRes.text()
    }
}



export async function deleteReq(method: string, token: string) : Promise<any> {
         let tokens = localStorage.getItem('token')

    const pageRes = await fetch(urlService + method, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${tokens}`

        }
    });

    return await pageRes
}


export async function deleteMethod(method: string, body: string, token: string) : Promise<any> {
    let tokens = localStorage.getItem('token')

    const pageRes = await fetch(urlService + method, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokens}`
        },
        body: body
    });

    try {
        const data = await pageRes.clone().json()
        data.httpStatusCode = pageRes.status;
        return data;
    } catch(err) {
        return await pageRes.text()
    }
}


export async function put(method: string, body: string, token: string, skipContentType: boolean = false) : Promise<any> {
    let tokens = localStorage.getItem('token')

    const basicHead = {
        Authorization: `Bearer ${tokens}`
    }

    if (!skipContentType) {
        basicHead['Content-Type'] = 'application/json';
    }

    const pageRes = await fetch(urlService + method, {
        method: 'put',
        headers: basicHead,
        body:body
    });

    return await pageRes
}

