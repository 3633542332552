import 'core-js/stable'
import 'react-app-polyfill/ie11'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { Auth0Provider } from "./react-auth0-spa";
import config from "./auth_config.json";
import history from "./utils/history";

import { Provider } from "react-redux";
import configureStore from "./store";
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core/styles';
import 'bootstrap/dist/css/bootstrap.min.css';

import pink from '@material-ui/core/colors/pink';

const onRedirectCallback = (appState:any) => {
    history.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };


const store = configureStore();



const theme = createTheme({
  palette: {
    primary: {
      main: "#842558",
    },
    secondary: {
      main: "#E93029"
    },
    info: {
      main: "#f2f2f2",
      light: "#f2f2f2",
      dark: "#414548",
    },
  },
});


ReactDOM.render(
<Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    redirect_uri={window.location.origin}
    audience={"mediavisionapp"}
    scope={"profile admin email"}
    cacheLocation={'localstorage'}
    mustAcceptTerms= {true}
    //onRedirectCallback={onRedirectCallback}
  >
      <Provider store={store}>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Provider>
  </Auth0Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
