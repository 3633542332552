import React, { useState } from "react";
import Chart from "react-google-charts";
import { PieChartType, PopulationData, xTypes } from "../../../types/charts";
import { Button } from "@material-ui/core";
import { exportData } from "../../../services/exportService";
import { useSelector } from "react-redux";
import { useAuth0 } from "./../../../react-auth0-spa";
import exportIcon from '../../../Assets/images/export.png'
import { AppState } from "../../../store";
import colorService from "../../../services/colorService";
import { NotificationContainer, NotificationManager } from 'react-notifications';


type PieChartProps = {
  graphs: Array<PieChartType>;
  title: string;
  footnote?: string;
  category?:any;
};

function transpose(list: any) {
  var first = list.map((c: any) => c[0]);
  var lst = [];
  lst.push(first);
  for (var i = 1; i < list[0].length; i++) {
    lst.push(list.map((d: any) => d[i]));
  }
  return lst;
}

const PieChart: React.FC<PieChartProps> = ({ graphs, title, footnote, category}) => {
  return (
    <div>
      {graphs.sort((a:any, b:any) => {

        return a.country.title.localeCompare( b.country.title)

      }).map((graph) => {
        return (
          <div key={graph._id}>
            <SwitchGraphByPieCount
              key={graph._id}
              graph={graph}
              prependCountry={graphs.length > 1}
              footnote={footnote}
              oneCountry={graphs.length === 1}
              category={category}
            />
          </div>
        );
      })}
    </div>
  );
};

type SwitchGraphByPieCountType = {
  graph: PieChartType;
  prependCountry?: boolean;
  footnote?: string;
  oneCountry: boolean;
  category?:any;
};

const SwitchGraphByPieCount: React.FC<SwitchGraphByPieCountType> = ({
  graph,
  prependCountry,
  footnote,
  oneCountry,
  category
}) => {

  const selectedYears = useSelector(
    (state: AppState) => state.graphs.selectedYears
  );

  const absRel = graph.category.absRel
  const [scaleToAbsolute, setScaleToAbsolute] = useState(graph.pies.length === 1 && absRel);

  let gr = JSON.parse(JSON.stringify(graph))
  gr.pies = gr.pies.filter((pie:any) => {
    let year = new Date(pie.label).getFullYear()
    if(year < selectedYears.min || year > selectedYears.max){
      return false
    }
    return true
  })
  let colors:string[] = [];

  let labels: any = [];
  if (gr.pies[0]) {
    labels = Object.keys(gr.pies[0].pie);
  }
  colors = colorService(labels);

  const decimalFormatter = {
    groupingSymbol: " ",
    fractionDigits:
    gr.hasDecimals && typeof gr.hasDecimals === "number"
        ? gr.hasDecimals
        : 2,
  };
  const { user } = useAuth0();


  switch (gr.pies.length) {
    case 1:
        let sum = 0;
        for(var key in gr.pies[0].pie){
            sum+=gr.pies[0].pie[key];
        }
      const options = {
        title:  "",
        legend: { position: "top", maxLines: 4 },
        colors: colors,
        animation: {
          startup: true,
          easing: "linear",
          duration: 700,
        },
        tooltip: {
          text: (sum === 1 && !scaleToAbsolute) ? "percentage" : null,
        },
        pieHole: 0.4,
      };

      return (
        <>
        <div className="settings__button">
        {
          gr.exportable ? (
            <Button
            onClick={() => {
              exportData([
                { name: "data",
                title: category.title,
                subTitle: category.subTitle,

                data: transpose(GraphDataToPieChart(gr, scaleToAbsolute)) },
              ], true, user).then((res) => {
                NotificationManager.success('Data sent to your registered email.');
              });
            }}

            style={{
              backgroundColor: "rgba(132, 37, 88, 0.08)",
              marginRight: 5,
              padding: "6px 15px"
            }}
          >
            <span className="button-icon"  style={{margin: 0}}>
              <img src={ exportIcon} />
            </span>
          </Button>
          ) : null
        }
        </div>

          <h3 style={{fontSize:20}}>{prependCountry ? gr.country.title : "" }</h3>

          <Chart
            chartType="PieChart"
            data={GraphDataToPieChart(gr, scaleToAbsolute)}
            graph_id={gr._id}
            width={"100%"}
            height={"400px"}
            options={options}
          />
        </>
      );
    default:
      let optionsBar: any = {
        title:  "",
        legend: { position: "top", maxLines: 4 },
        colors: colors,
        animation: {
          startup: true,
          easing: "linear",
          duration: 300,
        },
        isStacked: scaleToAbsolute ? "absolute" : 'percent',
      };

      if (gr.pies.length > 25) {
        optionsBar.fontSize = 10;
      }

      return (
        <div>
          {
            gr.exportable ?<Button
            onClick={() => {
              exportData([
                { name: "data", data: transpose(GraphDataToStackedBar(gr, scaleToAbsolute)), title: category.title, subTitle:category.subTitle },
              ], true, user).then((res) => {

                  NotificationManager.success('Data sent to your registered email.');

              });
            }}
            style={{
              backgroundColor: "rgba(132, 37, 88, 0.08)",
              marginRight: 5,
              padding: "6px 15px"
            }}
          >
            <span className="button-icon" style={{margin: 0}}>
              <img src={ exportIcon} />
          </span>
          </Button> : null
          }
          {
            absRel && (
              <Button
                color="primary"
                onClick={() => {
                  setScaleToAbsolute(!scaleToAbsolute);
                }}
              >
                <span className="button-icon">
                {
                  scaleToAbsolute ? "%" : "'000"
                }
                </span>
              </Button>
            )
          }

          <h3 style={{fontSize:20}}>{prependCountry ? gr.country.title : "" }</h3>

          <Chart
            chartType="ColumnChart"
            data={GraphDataToStackedBar(gr, scaleToAbsolute)}
            width={"100%"}
            height={"400px"}
            options={optionsBar}
            formatters={Array(5 - 1)
              .fill(null)
              .map((val, i) => {
                return {
                  column: i + 1,
                  type: "NumberFormat",
                  options: decimalFormatter,
                };
              })}
          />
                    <p>{footnote}</p>
                    <NotificationContainer />

        </div>
      );
  }
};

const GraphDataToPieChart = ({ pies, xType, country, populationData }: PieChartType, scaleToAbsolute: boolean) => {
  return [
    ["", ""],
    ...pies
      .map((pie) => {
        const pieDate = new Date(pie.label)
        let population = 1;
        if(scaleToAbsolute) {
          population = getPopulationData(populationData, pieDate, country.title, xType || xTypes.Year)
        }
        return [
          ...Object.keys(pie.pie).map((key) => {
            return [key, pie.pie[key] * population];
          }),
        ];
      })
      .flat(),
  ];
};

const getPopulationData = (populationData: PopulationData, date: Date, country: string, xType: xTypes) => {

  const transformedCountry = country.split(" ")[0].toLowerCase()

  let data;
  switch(transformedCountry) {
    case 'sweden':
      data = populationData.SE
      break;
    case 'norway':
      data = populationData.NO
      break;
    case 'finland':
      data = populationData.FI
      break;
    case 'denmark':
      data = populationData.DK
  }

  if(!data) {
    return 0
  }

  const year = date.getFullYear()
  const yearData = data.find((item) => item.year === year)
  if(!yearData) {
    return 0
  }

  if(xType === xTypes.Year) {
    return yearData.total
  }

  if(xType === xTypes.Quarter) {
    const quarter = getQuarter(date)
    return yearData.quarter[quarter]
  }

  return 0
}

const getQuarter = (date: Date) : 'Q1' | 'Q2' | 'Q3' | 'Q4' => {
  const month = (date.getMonth() + 1)
    .toString()
    .padStart(2, "0");

  switch (month) {
    case "01":
      return 'Q1'
    case "04":
      return 'Q2'
    case "07":
      return 'Q3'
    case "10":
      return 'Q4'
  }
  return 'Q1'
}

const GraphDataToStackedBar = (obj: PieChartType, scaleToAbsolute: boolean) => {
  let a = [
    [
      "",
      ...filterUnique(
        obj.pies
          .map((pie) => {
            return Object.keys(pie.pie);
          })
          .flat()
      ),
    ],
    ...obj.pies.map((pie) => {
      let label = pie.label ? pie.label : '';
      if (obj.xType === "quarter") {
        const year = new Date(pie.label).getFullYear().toString();
        const month = (new Date(pie.label).getMonth() + 1)
          .toString()
          .padStart(2, "0");
        var quarter;

        switch (month) {
          case "01":
            quarter = "Q1";
            break;
          case "04":
            quarter = "Q2";
            break;
          case "07":
            quarter = "Q3";
            break;
          case "10":
            quarter = "Q4";
            break;
        }
        label = quarter + " " + year;
      } else if (obj.xType === "year") {
        label = new Date(pie.label).getFullYear().toString();
      }
      let populationData = 1
      if(scaleToAbsolute) {
        populationData = getPopulationData(obj.populationData, new Date(pie.label), obj.country.title, obj.xType || xTypes.Year)
      }
      return [
        label,
        ...Object.keys(pie.pie).map((key) => {
          if (scaleToAbsolute) {
            const scaled = pie.pie[key] * populationData
            return Math.round(scaled/5)*5;
          }
          return pie.pie[key];
        }),
      ];
    }),
  ];
  return a;
};

function filterUnique(data: Array<string>) {
  const lookup = new Set();

  return data.filter((string) => {
    if (lookup.has(string)) {
      return false;
    } else {
      lookup.add(string);
      return true;
    }
  });
}

export default PieChart;
