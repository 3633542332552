import {get, post} from "./restTemplate"
import { MediaTypes } from "../store/Graphs/types"

export function getCategoriesForMediaType(mediaType : MediaTypes){
    const requestMediaType =
        Object.keys(MediaTypes).filter((key: string) =>  Object(MediaTypes)[key] === mediaType)[0];
    return get(`/categoriesformediatype?mediaType=${requestMediaType}`, "asd")
}

export function getGraphsForCategory(mediaType:MediaTypes, category:string){
    const requestMediaType =
        Object.keys(MediaTypes).filter((key: string) =>  Object(MediaTypes)[key] === mediaType)[0];
    return get(`/graphforcategory?mediaType=${requestMediaType}&categoryId=${category}`, "asd")
}

export function setHasAccept(){

    return get(`/accept`, "asd")
}

export function resetPass(){
    return get('/change', "asd");
}
