import Modal from "@material-ui/core/Modal";
import {Button, FormControl, InputLabel, TextField} from "@material-ui/core";
import Select from "../../components/forms/Select/Select";
import React, {useState} from "react";
import {Alert} from "@material-ui/lab";
import {createBarChart, createPieChart, createWaterfallChart, getChartsForCategory} from "../../services/chartService";
import {getModalStyle} from "./Styles";
import {extractErrorFromResponse} from "../../utils/utils";


const mediaType = [{value:"text", name:"Text"},{value:"video", name:"Video"},{value:"sound", name:"Audio"},{value:"overview", name:"Overview"},{value:"access", name:"Access"}]
const xTypeList = [{value:"year", name:"Year"},{value:"quarter", name:"Quarter"},{value:"other", name:"Other"}]
let decimals = [{value:"0", name:"0"},{value:"1", name:"1"}, {value:"2", name:"2"},{value:"3", name:"3"},{value:"4", name:"4"}]

export const AdminCategoryModal: React.FC<any> = ({addFileOpen,  setAddFileOpen,
                                                      classes, countries,
                                                      barType, category, setCharts
}) => {
    const [error, setError] = useState('')
    const [newTitle, setNewTitle] = useState('')
    const [modalStyle] = React.useState(getModalStyle);
    const [newMediaType, setNewMediaType] = useState("text")
    const [newCountry, setNewCountry] = useState("")
    const [hasDecimals, setHasDecimals] = useState(category.hasDecimals + "")
    const [xLabel, setXLabel] = useState("")
    const [yLabel, setYLabel] = useState("")
    const [xType, setXType] = useState("")
    const [yType, setYType] = useState("")


    async function createNewFile() {
        let obj: any = {
            file: {title: newTitle},
            data: null,
            mediaType: newMediaType,
            category: category._id,
            country: newCountry,
            hasDecimals: Number(hasDecimals)
        }
        let promise;

        switch (category.barType) {
            case "barGraph":
                obj.file.xType = xType
                obj.file.xLabel = xLabel
                obj.file.yLabel = yLabel
                obj.file.isPercentage = yType === "percentage"

                promise = createBarChart(obj)
                break;
            case "waterfallGraph":
                obj.file.xLabel = xLabel
                promise = createWaterfallChart(obj)
                break;
            case "pieGraph":
                obj.file.xType = xType

                obj.file.categoryLabel = xLabel
                promise = createPieChart(obj)
                break;
            default:
                console.log("error")
                return;

        }


        const response = await promise;

        if (response.httpStatusCode === 422) {
            setError(extractErrorFromResponse(response))
        }

        if (response.httpStatusCode === 200) {
            const chartsResponse = await getChartsForCategory(category._id)

            if (chartsResponse) {
                setCharts(chartsResponse.graphs)
                setXType("")
                setYLabel("")
                setXLabel("")
                setNewMediaType("text")
                setNewCountry("")
                setNewTitle("")
                setAddFileOpen(false)
            }
        }
    }


    return <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={addFileOpen}
        onClose={() => {
            setAddFileOpen(false)
        }}

    >
        <div style={modalStyle} className={classes.paper}>
            <div className="row">
                {error ?
                    <Alert className="mb-3" severity="error">{error}</Alert> : '' }
            </div>
            <h3>Add new file</h3>
            <div>
                <FormControl className={classes.defFormControl}>
                    <InputLabel></InputLabel>
                    {/* <Input type={"file"} ref={fileInput} onChange={(e) => {
                                        setNewTitle(fileInput.current.firstChild.files[0].name)
                                        }}/> */}
                    <TextField placeholder={"Title"} value={newTitle} onChange={(e) => {
                        setNewTitle(e.target.value)
                    }}/>

                </FormControl>
                <FormControl className={classes.defFormControl}>
                    <Select options={mediaType} label={"Media type"} defaultValue={"text"}
                            value={newMediaType} onChange={(value) => {
                        setNewMediaType(value.target.value)
                    }}/>
                </FormControl>
            </div>
            <div>
                <FormControl className={classes.defFormControl}>
                    <Select options={countries} label={"Country"} defaultValue={""} value={newCountry}
                            onChange={(value) => {
                                setNewCountry(value.target.value)
                            }}/>
                </FormControl>
            </div>
            <div>
                <FormControl className={classes.defFormControl}>
                    <Select options={decimals} label={"Has decimals"} defaultValue={""}
                            value={hasDecimals} onChange={(value) => {
                        setHasDecimals(value.target.value)
                    }}/>
                </FormControl>
            </div>

            <h3>Inställningar</h3>
            {
                barType === "barGraph" ? (
                    <div>
                        <div>
                            <FormControl className={classes.defFormControl}>
                                <TextField placeholder={"xLabel"} value={xLabel} onChange={(e) => {
                                    setXLabel(e.target.value)
                                }}/>
                            </FormControl>
                            <FormControl className={classes.defFormControl}>
                                <TextField placeholder={"yLabel"} value={yLabel} onChange={(e) => {
                                    setYLabel(e.target.value)
                                }}/>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.defFormControl}>
                                <Select options={xTypeList} label={"x type"} value={xType}
                                        onChange={(value) => {
                                            setXType(value.target.value)
                                        }}/>
                            </FormControl>
                            <FormControl className={classes.defFormControl}>
                                <Select options={[{
                                    value: "percentage",
                                    name: "Percentage"
                                }, {value: "other", name: "Other"}]} label={"y type"} value={yType}
                                        onChange={(value) => {
                                            setYType(value.target.value)
                                        }}/>
                            </FormControl>
                        </div>

                    </div>
                ) : null
            }

            {
                barType === "pieGraph" ? (
                    <div>
                        <div>
                            <FormControl className={classes.defFormControl}>
                                <TextField placeholder={"Category label"} value={xLabel}
                                           onChange={(e) => {
                                               setXLabel(e.target.value)
                                           }}/>
                            </FormControl>
                        </div>
                        <div>
                            <FormControl className={classes.defFormControl}>
                                <Select options={xTypeList} label={"xType"} value={xType}
                                        onChange={(value) => {
                                            setXType(value.target.value)
                                        }}/>
                            </FormControl>
                        </div>
                    </div>
                ) : null
            }
            {
                barType === "waterfallGraph" ? (
                    <div>
                        <TextField placeholder={"xLabel"} value={xLabel} onChange={(e) => {
                            setXLabel(e.target.value)
                        }}/>
                    </div>
                ) : null
            }

            <Button variant="contained" color="primary" onClick={() => {
                createNewFile()
            }}>
                Add file
            </Button>


        </div>
    </Modal>;
}
