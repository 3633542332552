import React, {useEffect, useState} from 'react';
import {Container, TextField, Button, Input, FormControl} from "@material-ui/core";
import Select from './../../components/forms/Select/Select'
import {getCountries} from './../../services/countryService'

import {Card, CardContent} from '@material-ui/core/';
import {bulkUpdate, bulkDownload} from './../../services/categoryService'
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles({
    card: {
        minWidth: 275,
        marginBottom: 12,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    defFormControl: {
        // width: 450,
        width: '100%',
        marginRight: 10,
        marginBottom: 10,
    },
    pos: {
        marginBottom: 12,
    },
});

function getBase64(file: any, cb: any) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}


const mediaType = [{value: "text", name: "Text"}, {value: "video", name: "Video"}, {
    value: "sound",
    name: "Audio"
}, {value: "overview", name: "Overview"}, {value: "access", name: "Access"}]


const AdminBulk: React.FC = () => {
    const classes = useStyles();
    const [newMediaType, setNewMediaType] = useState("text")
    const [countries, setCountries] = useState([])
    const [newCountry, setNewCountry] = useState("Sweden")
    const [isDone, setIsDone] = useState(false)

    function uploadFile(fileInput: any) {
        setIsDone(false)
        getBase64(fileInput.current.firstChild.files[0], (a: any) => {
            let obj: any = {
                data: a.split(",")[1]
            }

            bulkUpdate(obj).then((re) => {
                setIsDone(true)
            })
        })
    }

    let fileInput: any = React.createRef();
    useEffect(() => {
        getCountries().then((res) => {
            setCountries(res.map((cat: any) => {
                return {value: cat._id, name: cat.title}
            }))
        });

    }, [])

    return (
        <main className="page-admincountries">
            <Container>
                <h1>Bulkuppdatera</h1>
            </Container>
            <Container style={{marginTop: 20}}>
                <h2>Ladda upp din fil</h2>
                <div className="col-md-4">
                    <FormControl fullWidth={true} className={classes.defFormControl}>
                        <Input type={"file"} ref={fileInput} onChange={(e) => {
                        }}/>

                    </FormControl>
                </div>
                <Button onClick={() => {
                    uploadFile(fileInput)
                }} variant="contained" color="primary"> Apply</Button>
                {
                    isDone ? <p>Done!</p> : null
                }

            </Container>
            <Container style={{marginTop: 20}}>
                <h2>Ladda ner exportfil</h2>
                <div className="col-md-4">
                    <FormControl fullWidth={true} className={classes.defFormControl}>
                        <Select options={mediaType} label={"Media type"} defaultValue={"text"} value={newMediaType}
                                onChange={(value) => {
                                    setNewMediaType(value.target.value)
                                }}/>
                    </FormControl>
                </div>

                <div className="col-md-4">
                    <FormControl fullWidth={true} className={classes.defFormControl}>
                        <Select options={countries} label={"Country"} defaultValue={"sweden"} value={newCountry}
                                onChange={(value) => {
                                    setNewCountry(value.target.value)
                                }}/>
                    </FormControl>
                </div>

                <Button onClick={() => {
                    bulkDownload({mediaType: newMediaType, country: newCountry}).then((res) => {
                        var csvURL = window.URL.createObjectURL(res);
                        var tempLink = document.createElement('a');
                        tempLink.href = csvURL;
                        tempLink.setAttribute('download', 'export.xlsx');
                        tempLink.click();
                    })
                }} variant="contained" color="primary">Download</Button>
            </Container>
        </main>
    )
}

export default AdminBulk;
