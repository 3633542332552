import React, {useContext, useState} from 'react';
import downloadImage from "../../Assets/images/download-slim.svg";
import accessSlim from "../../Assets/images/access-slim.svg";
import {postReportData} from "../../services/reportService";
import {ThemeContext} from "../../pages/FrontPage/FrontPage";
import moment from "moment";

interface ReportObj {
    report_name: string,
    report_group: string,
    publishing_date: string,
    file: string,
    file_path: string,
}


export const GetReportRowsForUser = (reports: any[], filter: string, setIsOpen: Function, setIsOpenKPIFailed: Function)  => {
    filter = filter.toLowerCase()
    const [notificationSent, setNotificationSent] = useState(null);
    const theme = useContext(ThemeContext);
    const user = theme.user
    const roles = theme.user['https://mediavision.se/roles']
    const displayName = `${user['https://mediavision.se/app_metadata'].company} ${user.name}`

    const rows = reports.map((item: ReportObj, key) => {
        const tempReportName = item.report_name.toLowerCase()
        const containsChar = tempReportName.includes(filter) || item.report_group.includes(filter)
        let availableKPI = false
        if (!containsChar) {
            return <tr></tr>
        }

        availableKPI = roles.find(role => {
            return role.description.trim() === item.report_group.trim()
        })


        const downloadReport = async () => {
            setIsOpen(true)
            const body = JSON.stringify({
                name: displayName,
                reportObjName: item.report_name,
                reportName: item.file_path,
                userId: user,
                report: item
            });
            await postReportData(body).then(res => {
                setNotificationSent(res.status)
            });
        }

        const navigateToKPI = () => {
            window.location.href = '/data';
        }

        const showError = () => {
            setIsOpenKPIFailed(true)
        }

        return  <tr key={key} >
                <td style={styles.tableBodyRowTD}>
                    <div style={styles.left}>{item.report_name}</div>
                </td>
                <td style={{...styles.tableBodyRowTD}}>
                    <div style={styles.left}>{moment(item.publishing_date).format('YYYY-MM-DD')}</div>
                </td>
                <td style={styles.tableBodyRowTD}>
                    <a href="#" onClick={downloadReport} >
                        <img src={downloadImage} alt="download" />
                    </a>
                </td>
                <td style={styles.tableBodyRowTD}>
                    {availableKPI ?
                    <a href="#" onClick={navigateToKPI} >
                        <img src={accessSlim} alt="access-kpi" />
                    </a> :
                        <a href="#" onClick={showError} style={styles.disabled} >
                            <img src={accessSlim} alt="access-kpi" />
                        </a>
                    }
                </td>
            </tr>
    })
    return rows;
}


const styles = {
    tableBodyRowTD: {
        height: "65px",
        fontSize: "16px",
        fontWeight: 300,
        alignItems: "right" as const,
    },
    disabled: {
        opacity: 0.3,
        // cursor: 'inherit',
    },
    left: {
        width: "95%",
        marginLeft: "auto" as const,
        textAlign: "left" as const,

    }
}
