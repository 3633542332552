import {get, post, put} from "./restTemplate"

export function getCountries(){
    return get(`/countries`, "asd")
}

export function createCountry(country:string){
    return post(`/countries`,JSON.stringify({title:country}), "asd")
}


export function updateCountry(country:any){
    return put(`/countries`,JSON.stringify(country), "asd")
}
