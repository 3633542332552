import React, {useEffect, useRef, useState} from 'react';
import {Alert} from "@material-ui/lab";
import {ReportFilter} from "../filters/report-filter";
import {isAdmin} from "../../utils/utils";
import {useAuth0} from "../../react-auth0-spa";
import {Button, Container} from "@material-ui/core";
import {Table} from "react-bootstrap";
import useReportFetch from "./reportFetch";
import {uploadReportsAccessFile} from "../../services/categoryService";
import {getReportRows} from "./getReports";

export const ReportTable = ({
                                setEditModalIsOpen,
                                setIsOpen,
                                isOpen,
                                setReload,
                                reload,
                                setCurrentReport,
    setOverlay
})  => {
    const {user} = useAuth0();
    const [uploaded, setUploaded] = useState(false);
    const [notificationSent, setNotificationSent] = useState(null);
    const [filter, setFilter] = useState('');
    const {loading, data} = useReportFetch(reload, user);

    const inputRef = useRef(null);
    const uploadAccessFile = async (e) => {
        const reportData = new FormData()
        reportData.append('file', e.target.files[0])

        const response = await uploadReportsAccessFile(reportData)

        if (response.httpStatusCode === 200) {
            setUploaded(true)
            setReload({});
            setInterval(() => {
                setUploaded(false)
            }, 3000)
        }
    }

    const setCurrentReportFunc = (data: any) => {
        setCurrentReport(data)
        setEditModalIsOpen(true);
    }
    const isUserAnAdmin = isAdmin(useAuth0())
    const displayName = `${user['https://mediavision.se/app_metadata'].company} ${user.name}`
    const rows = getReportRows(data, setCurrentReportFunc, filter, isUserAnAdmin, displayName, user, setNotificationSent);

    const sendRequestToDownloadPermissions = async () => {
        setOverlay("loading");
        fetch(process.env.REACT_APP_BACKEND_URL + "/report-permissions", {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' +  localStorage.getItem('token')
            }
        }).then((res:any) => {
            return res.json();
        }).then((data:any) => {
            setOverlay(data)
        })
    }

    return <Container>
        <h1>Reports</h1>

        {uploaded ? <Alert className="mb-3" severity="success">Uploaded</Alert> : '' }
        {notificationSent &&  notificationSent === 200 ? <Alert className="mb-3" severity="success">E-mail has been sent</Alert> : '' }
        <section className="reports-page ">
            <div className={"table-header-block"}>
                <ReportFilter filter={filter} setFilter={setFilter} />

                {isAdmin(useAuth0()) ? <div>
                    <Button
                        variant="contained"
                        color="primary"
                        className="button me-1"
                        onClick={sendRequestToDownloadPermissions}
                    >
                        Download access XLS
                    </Button>

                    <input
                        accept="application/vnd.sealed.xls/*"
                        className="hide"
                        id="upload-access-file"
                        multiple
                        type="file"
                        required={true}
                        ref={inputRef}
                        onChange={(e) => {
                            uploadAccessFile(e);
                            e.target.value = null;
                        }}
                    />

                    <label htmlFor="upload-access-file" >
                        <Button variant="contained" className="me-1" component="span" color="primary">
                            Upload access XLS
                        </Button>
                    </label>

                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setIsOpen(!isOpen)
                        }}
                    >
                        Create report
                    </Button>
                </div> : ''}
            </div>


            <Table striped hover className="reports-table" variant="test">
                <thead>
                <tr>
                    <th>Report name</th>
                    <th>Report group</th>
                    <th>Publish date</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {loading || rows.length === 0 ? <tr key='empty-row'><td colSpan={4}>No rows</td></tr> : rows}
                </tbody>
            </Table>
        </section>


    </Container>

}
