export interface ChartType {
    _id: string
    title: string,
    country: {
      _id: string,
      title: string,
    }, 
    
}

export type PopulationData = {
  SE: PopulationYear[],
  NO: PopulationYear[],
  FI: PopulationYear[],
  DK: PopulationYear[],
}
type PopulationYear = {
  year: number,
  total: number,
  quarter: {
    Q1: number,
    Q2: number,
    Q3: number,
    Q4: number
  }
}

export interface BarChartType extends ChartType {
  xValues: Array<Date>
  xLabel: string
  yLabel:string,
  xType: xTypes,
  isPercentage: Boolean,
  hasDecimals:Number,
  bars: Array<{
    yValues: Array<number|null>
    label: string
  }>,
  exportable?: Boolean
  populationData: PopulationData,
  category: {
    absRel: boolean
  }
}

type PieObjectGeneric = { [key: string]: number };

export type Pie = {
  label: string,
  pie: PieObjectGeneric,
  _id: string,
}
export interface PieChartType extends ChartType {
  pies: Array<Pie>,
  xType?: xTypes,
  categoryLabel:string,
  hasDecimals:Number,
  category:{
    _id: string,
    title: string,
    absRel: boolean,
  },
  exportable?: Boolean,
  populationData: PopulationData,
}

export interface WaterfallChartType extends ChartType {
  seqLabels: Array<string>,
  sequences: Array<Array<number>>,
  xLabel: string,
  xValues: Array<string|number>
}

export enum xTypes {
  Year = "year",
  Quarter = "quarter",
}
