import React from 'react';
import {useAuth0} from "../react-auth0-spa";

interface Links {}

const Links : React.FC = () => {
    const { logout, user } = useAuth0();

    if (user.email !== "mattias.lundgren@awave.com") {
        logout()
    }

    return (
        <section className="links-page">
            <div><a href="/faq">/faq</a></div>
            <div><a href="/data">/data</a></div>
            <div><a href="/me">/me</a></div>
            <div><a href="/streaming">/streaming</a></div>
            <div><a href="/stream/:id">/stream/:id</a></div>
            <div><a href="/admin/categories">/admin/categories</a></div>
            <div><a href="/admin/countries">/admin/countries</a></div>
            <div><a href="/admin/headcategories">/admin/headcategories</a></div>
            <div><a href="/admin/topheadcategories">/admin/topheadcategories</a></div>
            <div><a href="/admin/roles">/admin/roles</a></div>
            <div><a href="/admin/streaming">/admin/streaming</a></div>
            <div><a href="/admin/bulk">/admin/bulk</a></div>
            <div><a href="/admin/download-log">/admin/download-log</a></div>
        </section>

    )
}

export default Links;
