import React from 'react';
import { WaterfallChartType } from '../../../types/charts';
import Chart from 'react-google-charts';

type WaterfallChartProps = {
    graphs: Array<WaterfallChartType>
    title: string,
}

const data = [
    ["day", "a", "b", "c", "d"],
    ["Monday", 10, 0, 10, 0],
    ["Tuesday", 20, 10, 20, 10],
    ["Wednesday", 5, 10, 5, 10],
]

const WaterfallChart : React.FC<WaterfallChartProps> = ({graphs, title}) => {
    return (
        <div>
            {
                graphs.map(graph => {
                    return (
                        <div className="barchart chart">
                <Chart
                  chartType="CandlestickChart"
                  width="100%"
                  height="500px"
                  //data={GraphDataToWaterfallChart(graph)}
                  data={data}
                  options={{
                    legend: 'none',
                    bar: { groupWidth: '100%' }, // Remove space between bars.
                    candlestick: {
                      fallingColor: { strokeWidth: 0, fill: '#a52714' }, // red
                      risingColor: { strokeWidth: 0, fill: '#0f9d58' }, // green
                    },
                  }}
                  />
            </div>
                    )
                })
            }

        </div>

    )
}

const GraphDataToWaterfallChart = (graph : WaterfallChartType) => {
    return [
       [graph.xLabel, ...graph.seqLabels],
       ...graph.sequences.map((sequence, i) => {
           return [
               String(graph.xValues[i]),
               ...sequence
           ]
       })
    ];
}


export default WaterfallChart;
