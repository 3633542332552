import {urlService} from "./config"

export function exportData(data:any, isPercent :boolean, user:any){
    console.log(user)

    return post(`/export?isPercent=${isPercent ? "true" : "false"}&email=${user.email}`, JSON.stringify(data), "asd")
}

export async function post(method: string, body: string, token: string) : Promise<any> {
     let tokens = localStorage.getItem('token')

    const pageRes = await fetch(urlService + method, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokens}`
            
        },
        body: body
    });
    try {

        return await pageRes.clone().blob()
    } catch(err) {
       return await pageRes.text()
    }
}