import React, {useState} from 'react';
import {ReportFilter} from "../filters/report-filter";
import {isAdmin} from "../../utils/utils";
import {useAuth0} from "../../react-auth0-spa";
import {Button, Container} from "@material-ui/core";
import {Table} from "react-bootstrap";
import {ReportGroupRows} from "./ReportGroupRows";

export const ReportGroupTable = ({
                                setEditModalIsOpen,
                                setOverlay,
                                setIsOpen,
                                isOpen,
                                setCurrentReport,
    reportGroupLoading,
    data
})  => {
    const {user} = useAuth0();
    const [filter, setFilter] = useState('');


    const setCurrentReportFunc = (data: any) => {
        setCurrentReport(data)
        setEditModalIsOpen(true);
    }
    const isUserAnAdmin = isAdmin(useAuth0())
    const displayName = `${user['https://mediavision.se/app_metadata'].company} ${user.name}`

    const rows = ReportGroupRows(data, setCurrentReportFunc, filter, isUserAnAdmin, setOverlay, displayName);

    return <Container>
        <h1>Report Groups</h1>

        <section className="reports-page ">
            <div className={"table-header-block"}>
                <ReportFilter filter={filter} setFilter={setFilter} />

                {isAdmin(useAuth0()) ? <div>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            setIsOpen(!isOpen)
                        }}
                    >
                        Create report group
                    </Button>
                </div> : ''}
            </div>


            <Table striped hover className="reports-table" variant="test">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Publish date</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                {reportGroupLoading || rows.length === 0 ? <tr key='empty-row'><td colSpan={5}>No rows</td></tr> : rows}
                </tbody>
            </Table>
        </section>


    </Container>
}


