import {get, post,put, deleteReq} from "./restTemplate"

export function createStream(title:string, description:string, URL:string, roles:any, date:any){
    return post(`/streaming/create`, JSON.stringify({title,description, URL, roles,date}),"asd")
}


export function getStreamsAdmin(){
    return get(`/streaming/admin`, "asd")
}

export function updateStream(stream:any){
    return post(`/streaming/update`, JSON.stringify(stream), "asd")
}

export function getStreams(){
    return get('/streaming/', "")
}

export function getStream(id:string){
    
    return get('/streaming/single?id=' + id, "")
}

export function deleteStream(id:string){
    return deleteReq('/streaming/single?id=' + id, "")
}

