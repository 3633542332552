import React, { useEffect, useState, useMemo  } from 'react';
import { Container, TextField, Button, Input, Card, CardContent} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { getStreams} from './../../services/streamingService'
import {
    Link
  } from "react-router-dom";

import _ from 'lodash';
const useStyles = makeStyles({
  
});



const StreamingOverview : React.FC = () => {
   
    const [streams, setStreams] = useState([]);
    
    useEffect(() => {
        getStreams().then((res) => {
            console.log("streams", res)
            setStreams(res)
        })
    }, [])
    return (
        <section className="cards-overview">
            <div className="main-inner">
                <h1>Streaming</h1>
                <div className="cards-container">
                {
                    streams.map((stream:any) => {
                        return (
                            <Link to={"/stream/" + stream._id} >
                                <h3>{stream.title}</h3>
                                <p className="date">{stream.date}</p>
                                <p>{stream.description}</p>
                            </Link>
                        )
                    })
                }
                </div>
            </div>
        </section>
    )
    
}

export default StreamingOverview;
