import { GraphState, GraphActionTypes, SET_SELECTED_CATEGORY, SET_SELECTED_COUNTRY, SET_SELECTED_MEDIATYPE, SET_MEDIATYPE, SET_CATEGORY_FOR_MEDIATYPE, SET_SELECTED_YEARS, MediaTypes } from "./types";

const initialState : GraphState = {
    selectedCategoryID : [],
    selectedCountryID: new Set(),
    selectedMediaType: null,
    mediatypes: new Map(),
    selectedYears: {
        min: 2000,
        max: 2020,
    }
}

export function graphReducer(
    state = initialState,
    action : GraphActionTypes
) : GraphState {
    switch(action.type) {
        case SET_SELECTED_CATEGORY:
            const selectedCagetories = (action.payload);
            return {
                ...state,
                selectedCategoryID: selectedCagetories,
                selectedCountryID: new Set(),
            }
        case SET_SELECTED_COUNTRY:

            const selectedCountries = new Set(action.payload);

            return {
                ...state,
                selectedCountryID: selectedCountries
            }
            
        case SET_SELECTED_MEDIATYPE:
            const newMediaTypes = new Map(state.mediatypes);

            const selectedMediaTypes = Array.from(state.mediatypes.values()).map(medietype => medietype.type);
            if(!selectedMediaTypes.includes(action.payload)) {
                newMediaTypes.set(action.payload, {
                    type: action.payload,
                    isFetching: true,
                    categories: new Map(),
                })
            }

            return {
                ...state,
                selectedMediaType: action.payload,
                mediatypes: newMediaTypes,
                selectedCategoryID: [],
                selectedCountryID: new Set(),
            }
        case SET_MEDIATYPE:
            const newMedietypes = new Map(state.mediatypes);

            newMedietypes.set(action.meta.mediatype, action.payload);
            return {
                ...state,
                mediatypes: newMedietypes,
            }
        case SET_CATEGORY_FOR_MEDIATYPE: 
        
            const mediatype = state.mediatypes.get(action.meta.mediatype);
            if(!mediatype) {
                return state;
            }

            const mediaTypeCategories = new Map(mediatype.categories);
            mediaTypeCategories.set(action.payload._id, action.payload);
            mediatype.categories = mediaTypeCategories;

            const mediatypes = new Map(state.mediatypes);
            mediatypes.set(action.meta.mediatype, mediatype);

            return {
                ...state,
                mediatypes: mediatypes,
            }
        case SET_SELECTED_YEARS:
            return {
                ...state,
                selectedYears: action.payload,
            }
        default:
            return state
    }
}