import Modal from "@material-ui/core/Modal";
import {Button, Container, FormControl, Input, InputLabel} from "@material-ui/core";
import React, {useState} from "react";
import {getModalStyle, useStyles} from "../../pages/AdminCategories/Styles";
import {createReportGroup, createReports} from "../../services/categoryService";
import {Alert} from "@material-ui/lab";

export const CreateReportGroupModal : React.FC<{isOpen: boolean, setIsOpen: any, setReload: any}> = ({isOpen, setIsOpen, setReload}) => {
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();

    const [name, setName] = useState('');
    const [error, setError] = useState('');

    const submitReportGroup = () => {
        setError('');
        let reportData = new FormData()

        reportData.append('name', name)

        createReportGroup(reportData).then(async (res) => {
            setReload({});

            if (res.httpStatusCode === 422) {
                setError(res.details[0].message)
                return false;
            }

            if (res.errmsg) {
                setError("Validation error (probably duplicate)")
                return false;
            }

            if (res.errors && Object.keys(res.errors).length > 0) {
                setError(res.message)
                return false;
            }

            setName('');
            setError('');

            setIsOpen(false);
        }).catch(e => {
            console.log('e' , e)
        });

    }


    return <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={() => {
            setIsOpen(false)
        }}

    >
        <div style={modalStyle} className={classes.paper}>
            <Container>
                {error !== '' ?
                    <Alert className="mb-3" severity="error">{error}</Alert> : '' }

                <h3>Create report group</h3>
                <FormControl className={classes.defFormControl}>
                    <InputLabel>Name</InputLabel>
                    <Input value={name} onChange={(e) => setName(e.target.value)}/>
                </FormControl>

                <div className="div-space center mb-2">
                    <label htmlFor="contained-button-file" className="center ">
                        <Button onClick={submitReportGroup} variant="contained" color="primary" component="span">
                            Create
                        </Button>
                    </label>
                </div>
            </Container>

        </div>
    </Modal>

}


