import React, {useState} from "react";
import {deleteTopHeadCategory, updateTopHeadCategory} from "../../services/categoryService";
import {Button, Card, CardContent, TextField} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ConfirmationModal from "../../components/layout/ConfirmationModal";

const useStyles = makeStyles({
    card: {
        minWidth: 275,
        marginBottom: 12,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },

    space: {
        marginRight: 5,
    }
});

export const TopHeadCategory : React.FC<any> = (props) => {
    const [editing, setEditing] = useState(false)
    const [text, setText] = useState(props.category.title)
    const [order, setOrder] = useState(props.category.order)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const classes = useStyles();

    const updateCategory = () => {
        let cat = props.category
        cat.title=text
        cat.order =order
        updateTopHeadCategory(cat).then((res) => {
            setEditing(false)
        })
    }

    const toggleDeleteModal = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation)
    }


    const removeCategory = () => {
        deleteTopHeadCategory(props.category._id).then((res) => {
            props.reload()
        })
    }


    return (
        <Card className={classes.card}>
            <CardContent>
                {
                    editing ?
                        (<div>
                            <TextField value={text} onChange={(e) => setText(e.target.value)} />
                            <TextField value={order} onChange={(e) => setOrder(e.target.value)} />

                            <Button  onClick={() => {setEditing(false)}}>
                                Avbryt
                            </Button>
                            <Button  onClick={() => {updateCategory()}}>
                                Uppdatera
                            </Button>
                        </div>)
                        :
                        (
                            <div>
                                <h3>{props.category.title}</h3>
                                <Button className={classes.space} variant="contained" onClick={() => setEditing(true)}>Edit</Button>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                    setShowDeleteConfirmation(true)
                                }}>Delete</Button>
                            </div>
                        )
                }
            </CardContent>

            <ConfirmationModal show={showDeleteConfirmation} onConfirmation={removeCategory} toggleFunc={toggleDeleteModal}/>
        </Card>
    )
}
