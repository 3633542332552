import React, {useEffect, useState} from 'react';

import {Button, Container, FormControl, Input, InputLabel} from "@material-ui/core";
import {createCategory, getCategories, getHeadCategories} from './../../services/categoryService'
import Select from './../../components/forms/Select/Select'
import Modal from '@material-ui/core/Modal';
import {getModalStyle, useStyles} from "./Styles";
import {Category} from "./AdminCategory";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import {Alert} from "@material-ui/lab";
import {useParams} from "react-router-dom";


interface HTMLInputEvent extends Event {
    target: HTMLInputElement & EventTarget;
}

const options = [{value: "barGraph", name: "Bar graph"}, {value: "pieGraph", name: "Pie graph"}]


const AdminCategories: React.FC = () => {

    const [categories, setCategories] = useState([]);
    const [headCategories, setHeadCategories] = useState([]);
    const [pickedHeadCategory, setPickedHeadCategory] = useState("");


    const [pickedCategory, setPickedCategory] = useState("");
    const [searchText, setSearchText] = useState("");

    const [modalStyle] = React.useState(getModalStyle);

    const [categoryText, setCategoryText] = useState("")
    const [categoryType, setCategoryType] = useState("barGraph")
    const [footnote, setFootnote] = useState("")

    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [error, setError] = useState('')


    const params:any = useParams()
    useEffect(() => {
        if (params && params.id) {
            setPickedCategory(params.id)
        }
    }, [])

    const addCategory = () => {
        setLoading(true)
        createCategory(categoryText, categoryType, footnote, pickedHeadCategory).then((res) => {
            if (res.httpStatusCode !== 200) {
                if (res.details && res.details[0] && res.details[0].message) {
                    setError(res.details[0].message)
                }
            } else {
                setCategoryText("")
                getCategoriesRoutine()
                setIsOpen(false)
                setPickedCategory(res._id)
            }
        })
    }

    const getCategoriesRoutine = () => {
        setLoading(true)
        getCategories().then((res) => {
            setCategories(res)
            setLoading(false)
        })
    }

    const getHeadCategoriesRoutine = () => {
        getHeadCategories().then((res) => {
            setHeadCategories(res.map((cat: any) => {
                return {value: cat._id, name: cat.title}
            }))
        })
    }

    useEffect(() => {
        getCategoriesRoutine()
        getHeadCategoriesRoutine()
    }, [])


    return (
        <main className="page-admincategories" style={{marginTop: "100px"}}>
            <Container>
                <div>
                    <h1 style={{display: "inline-block"}}>Categories</h1>
                    <Button variant="contained" color="primary"
                            style={{marginTop: 20, float: "right", marginBottom: 20}} onClick={() => {
                        setIsOpen(true)
                    }}>
                        Add new Category
                    </Button>
                </div>
                <div style={{marginBottom: 50}}>
                    <form>
                        <p>Pick the category you want to edit or create a new one</p>

                        <Autocomplete
                            id="category-autocomplete"
                            size="small"
                            options={categories.map((cat: any) => {
                                return {title: String(cat.title), value: String(cat._id)}
                            })}
                            getOptionLabel={(option: any) => option.title}
                            onChange={(e: any, newValue: any) => {
                                setPickedCategory(newValue.value)
                            }}
                            renderInput={(params) => <TextField {...params} label="Category" margin="normal"
                                                                variant="standard"/>}
                        />
                    </form>

                </div>
                {
                    categories.map((category: any) => {
                        if (pickedCategory !== "all") {
                            if (category._id === pickedCategory) {
                                return <Category headCategories={headCategories ? headCategories : []}
                                                 searchText={searchText} category={category} updateCategories={() => {
                                    getCategoriesRoutine()
                                }}/>
                            } else {
                                return
                            }
                        }

                        return <Category headCategories={headCategories ? headCategories : []} category={category}
                                         searchText={searchText} updateCategories={() => {
                            getCategoriesRoutine()
                        }}/>
                    })
                }

            </Container>
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={isOpen}
                onClose={() => {
                    setIsOpen(false)
                }}

            >
                <div style={modalStyle} className={classes.paper}>
                    <Container>
                        {error ?
                        <Alert className="mb-3" severity="error">{error}</Alert> : '' }

                        <h3>Add a category</h3>

                        <FormControl className={classes.defFormControl}>
                            <InputLabel>Title</InputLabel>
                            <Input value={categoryText} onChange={(e) => setCategoryText(e.target.value)}/>
                        </FormControl>
                        <FormControl className={classes.defFormControl}>
                            <InputLabel>Footnote</InputLabel>
                            <Input value={footnote} onChange={(e) => setFootnote(e.target.value)}/>
                        </FormControl>
                        <FormControl className={classes.defFormControl}>
                            <Select options={options} label={"Graphtype"} defaultValue={""} value={categoryType}
                                    onChange={(value) => {
                                        setCategoryType(value.target.value)
                                    }}/>
                        </FormControl>
                        <FormControl className={classes.defFormControl}>
                            <Select options={headCategories} label={"Huvudkategori"} defaultValue={""}
                                    value={pickedHeadCategory} onChange={(value) => {
                                setPickedHeadCategory(value.target.value)
                            }}/>
                        </FormControl>
                        <div>
                            <Button color="primary" variant="contained" onClick={addCategory}>
                                Add category
                            </Button>
                        </div>
                    </Container>

                </div>
            </Modal>
        </main>
    )
}

export default AdminCategories;
