import React, {useState, useEffect} from "react";
import { Container, Modal, Button } from '@material-ui/core';
import Filters from './Filters';
import RenderGraphs from './RenderGraphs';
import { useAuth0 } from "../../react-auth0-spa";
import { makeStyles } from '@material-ui/core/styles';
import {setHasAccept as setHasAcceptFunc} from './../../services/userService'
const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 600,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

function rand() {
    return 0
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}



const Graphs: React.FC = () => {
    const { user } = useAuth0();
    const classes = useStyles();
    // getModalStyle is not a pure function, we roll the style only on the first render
    const [modalStyle] = React.useState(getModalStyle);
    const [hasAccept, setHasAccept] = React.useState(user["https://mediavision.se/app_metadata"].has_accept)


    const [ contactText, setContactText] = useState<string>("");



    useEffect(() => {
      fetch("https://mediavision.se/wp-json/acf/v2/options",  {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
      }).then((res:any) => {
        return res.json();
      }).then((a:any) => {
         setContactText(a.acf.terms_of_use)

      })}, []);


    return (
        <main className="page-graphs" style={{ marginTop: "100px" }}>
            {!hasAccept ? (
                <Modal
                    open={!hasAccept}
                    onClose={() => { }}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <div style={modalStyle} className={classes.paper}>
                        <h2 style={{marginBottom:10}} id="simple-modal-title">Terms of use</h2>
                        <div id="simple-modal-description" style={{height:300, overflow:"scroll"}} dangerouslySetInnerHTML={{__html: contactText}}>

                        </div>
                        <div style={{ margin:"0 auto", marginTop:20,textAlign:"center" }}>
                        <Button  onClick={() => {window.location.href = "/"}} variant="contained" style={{marginRight:40}}>
                            Decline
                        </Button>
                        <Button color="primary" variant="contained" onClick={() => {setHasAcceptFunc().then((res) => {
                            setHasAccept(true)
                        })}}>
                            Accept
                        </Button>
                        </div>

                    </div>
                </Modal>
            ) : null}
            <Container>
                <Filters />
                <RenderGraphs key={contactText} />
            </Container>
        </main>
    )
}

export default Graphs;


