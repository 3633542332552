import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from '../../store';
import { MediaTypes, Mediatype, ChartTypes, Categories } from '../../store/Graphs/types';
import BarChart from '../../components/charts/BarChart/BarChart';
import PieChart from '../../components/charts/PieChart/PieChart';
import { BarChartType } from '../../types/charts';
import WaterfallChart from '../../components/charts/WaterfallChart/WaterfallChart';
import { Button } from "@material-ui/core";
import { setSelectedCategory } from '../../store/Graphs/actions';

const RenderGraphs : React  .FC = () => {
    const selectedMediaType = useSelector((state: AppState) => state.graphs.selectedMediaType);
    const selectedCategory = useSelector((state: AppState) => state.graphs.selectedCategoryID);
    const selectedCountries = useSelector((state: AppState) => state.graphs.selectedCountryID);
    const mediatypes : Map<MediaTypes, Mediatype> = useSelector((state: AppState) => state.graphs.mediatypes);
    let dispatch = useDispatch()
    if(!selectedMediaType) {
        return (
            <p>
                Pick a media type
            </p>
        )
    }
    const mediatype = mediatypes.get(selectedMediaType)

    if( !mediatype) {
        return (
            <p>
            </p>
        )
    }

    const categories = Array.from(selectedCategory).map(id => {
        return mediatype.categories.get(id)
    })

    const removeCategory = (c:string) => {

        dispatch(setSelectedCategory( selectedCategory.filter(x => x!== c)));
    }


    return (
        <section>
            {
                categories.length === 0 ? (
                    <div style={{border:"2px #7d1147 solid", textAlign:"center", padding:20, width:"50%", marginLeft:"auto", marginRight:"auto"}}>
                        <p style={{fontWeight:400}}>Welcome!</p>
                        <p style={{fontWeight:400}}>Start by selecting a category and then navigate to desired KPI.<br /> If you need information on how to use the tool - see the <a href="https://www.mediavision.se/wp-content/uploads/2021/05/MII-Guidelines-1.pdf">MII Guidelines.</a></p>
                        <p></p>
                        <p style={{fontWeight:400}}>Let's get started!</p>
                    </div>
                ) : null
            }
            {
                categories.map((category : (Categories | undefined)) => {

                    if(!category) {
                        return (<div key='none' ></div>)
                    }
                    if(category.isFetching) {
                        return (<div key='fetching' ></div>)
                    }


                    if(category.type === ChartTypes.BarChart) {
                        const charts = category.charts.values();
                        const filteredCharts = Array.from(charts).filter(chart => {
                            if(selectedCountries.size === 0) {
                                return true;
                            }
                            return selectedCountries.has(chart.country._id);
                        });
                        return (
                            <div className="section-graph" key={category._id}>
                                <div className="section-graph__header">
                                    <div className="flex-container">
                                        <h3>{category.title}</h3>
                                        <h4>{category.subtitle}</h4>
                                        <Button color="primary" onClick={()=> {
                                            removeCategory(category._id);
                                        }}>
                                            <span className="button-icon">
                                                X
                                            </span>

                                        </Button>
                                    </div>
                                </div>
                                {
                                    filteredCharts.length === 0 && (
                                        <p>Det finns ingen data för de valda länderna i denna kategorin</p>
                                    )
                                }
                                <BarChart graphs={filteredCharts} footnote={category.footnote} category={category} title={category.title} showCombinedAlternative={category.showCombinedAlternative} showCombined={category.showCombined} />
                            </div>
                        )
                    }

                    if(category.type === ChartTypes.PieChart) {
                        const charts = category.charts.values();
                        const filteredCharts = Array.from(charts).filter(chart => {
                            if(selectedCountries.size === 0) {
                                return true;
                            }
                            return selectedCountries.has(chart.country._id);
                        });

                        return (
                            <div className="section-graph" key={category._id}>
                                <div className="section-graph__header">
                                    <div className="flex-container">
                                        <h3>{category.title}</h3>
                                        <h4>{category.subtitle}</h4>
                                        <Button color="primary" onClick={()=> {
                                            removeCategory(category._id);
                                        }}>
                                            <span className="button-icon">
                                                X
                                            </span>
                                        </Button>
                                    </div>
                                </div>

                                {
                                    filteredCharts.length === 0 && (
                                        <p>Det finns ingen data för de valda länderna i denna kategorin</p>
                                    )
                                }
                                <PieChart graphs={filteredCharts} category={category} footnote={category.footnote} title={category.title} />
                            </div>
                        )
                    }

                    if(category.type === ChartTypes.WaterfallChart) {
                        const charts = category.charts.values();
                        const filteredCharts = Array.from(charts).filter(chart => {
                            if(selectedCountries.size === 0) {
                                return true;
                            }
                            return selectedCountries.has(chart.country._id);
                        });

                        return (
                            <div className="section-graph" key={category._id}>
                                <div className="section-graph__header">
                                    <h3>{category.title}</h3>
                                    <h4>{category.subtitle}</h4>
                                </div>
                                {
                                    filteredCharts.length === 0 && (
                                        <p>Det finns ingen data för de valda länderna i denna kategorin</p>
                                    )
                                }
                                <WaterfallChart graphs={filteredCharts} title={category.title} />
                            </div>
                        )
                    }

                    return (
                        <div className="section-graph" key='not_supported'>
                            <p>Graph type not supported (yet)</p>
                        </div>
                    )
                })
            }
        </section>
    )
}

export default RenderGraphs;

