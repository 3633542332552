import Modal from "@material-ui/core/Modal";
import {Button, Container, FormControl, Input, InputLabel, MenuItem, Select} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {getModalStyle, useStyles} from "../../pages/AdminCategories/Styles";
import {deleteReports, updateReports} from "../../services/categoryService";
import {ReportInterface} from "./interfaces";
import moment from "moment";
import {Alert} from "@material-ui/lab";

const readFile = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
};


export const EditModal: React.FC<{
    isOpen: boolean,
    setIsOpen: any,
    report: ReportInterface,
    setReload: any,
    reportGroups: any[]    }> = ({isOpen, setIsOpen, report, setReload, reportGroups}) => {
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();

    const [reportName, setReportName] = useState('');
    const [publishingDate, setPublishingDate] = useState('');
    const [period, setPeriod] = useState('');
    const [reportGroup, setReportGroup] = useState('');
    const [error, setError] = useState('');
    const [file, setFile] = useState(null);
    const [filename, setFilename] = useState('');
    const [currentReportGroup, setCurrentReportGroup] = useState('');

    useEffect(() => {
        setReportName(report.report_name);
        setCurrentReportGroup(report.report_group);
        let publishDate = '';
        if (report.publishing_date) {
            publishDate = moment(report.publishing_date).format('YYYY-MM-DD');
        }
        setPublishingDate(publishDate);
        setPeriod(report.period);
        setFilename(report.file_name ?? '');
    }, [report]);

    const destroyReport = () => {
        const reportData = {
            id: report._id
        };

        deleteReports(reportData).then(async (res) => {
            const status = res.status ?? res.httpStatusCode;

            setIsOpen(false);
            setReload({});

            if (status === 200) {
                // setError('')
                // setEditing(false)
            }
        })
    }

    const submitReport = async () => {
        const reportId: string = report._id.toString()
        let reportData = new FormData()
        if (file && file[0]) {
            reportData.append('file', file[0])
        }
        reportData.append('report_name', reportName)
        reportData.append('report_group', currentReportGroup)
        reportData.append('publishing_date', publishingDate)
        reportData.append('period', period)
        reportData.append('id', reportId)
        reportData.append('file_name', filename)
        reportData.append('file_path', filename)


        updateReports(reportData).then(async (response) => {
            setReload({});
            let originResponse = response;
            let res = await response.json()

            if (res.details && res.details[0]?.message) {
                setError(res.details[0].message)
                return false;
            }

            if (res.httpStatusCode === 422) {
                setError(res.details[0]?.message)
                return false;
            }

            if (res.status === 422) {
                setError(res.statusText)
                return false;
            }

            if (res.errors && Object.keys(res.errors).length > 0) {
                setError(res.message)
                return false;
            }

            setReportName('');
            setPublishingDate('');
            setPeriod('');
            setReportGroup('');
            setError('');
            setFile(null);
            setFilename('');

            setIsOpen(false);
        }).catch(e => {
            console.log('err res', e)
        })
    }


    const handleChange = (event: any) => {
        setCurrentReportGroup(event.target.value as string);
    };

    const reportGroupOptions = reportGroups.map((reportGroup: any) => {
        return <MenuItem key={reportGroup.name} value={reportGroup.name}>{reportGroup.name}</MenuItem>
    })


    return <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={() => {
            setIsOpen(false)
        }}

    >
        <div style={modalStyle} className={classes.paper}>
            <Container>
                {error ?
                    <Alert className="mb-3" severity="error">{error}</Alert> : '' }

                <h3>Update report {currentReportGroup}</h3>
                <FormControl className={classes.defFormControl}>
                    <InputLabel>Report name</InputLabel>
                    <Input value={reportName} onChange={(e) => setReportName(e.target.value)}/>
                </FormControl>
                <FormControl className={classes.defFormControl}>
                    <InputLabel>Publishing date</InputLabel>
                    <Input value={publishingDate} onChange={(e) => setPublishingDate(e.target.value)}/>
                </FormControl>

                <FormControl className={classes.defFormControl}>
                    <InputLabel>Period</InputLabel>
                    <Input value={period} onChange={(e) => setPeriod(e.target.value)}/>
                </FormControl>


                <FormControl className={classes.defFormControl}>
                    <InputLabel>Report group</InputLabel>
                    <Select name="report-group" onChange={handleChange} value={currentReportGroup} label={'report group'} >
                        {reportGroupOptions}
                    </Select>
                </FormControl>


                <div className="div-space center mb-2">
                    <input
                        accept="application/vnd.sealed.xls/*"
                        className="hide"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                                const newFileName = e.target.files[0].name ?? '';
                                setFilename(newFileName);
                                setFile(e.target.files ?? '');
                            }
                        }}
                    />
                    <label htmlFor="contained-button-file" className="center ">
                        <Button variant="contained" component="span">
                            Upload
                        </Button>
                    </label>
                    <p className="center p-2">{filename}</p>
                </div>

                <div className="button-block">

                    <Button color="secondary" variant="contained" onClick={destroyReport}>
                        Delete
                    </Button>
                    <Button color="primary" variant="contained" onClick={submitReport}>
                        Update
                    </Button>

                </div>


            </Container>
        </div>
    </Modal>

}


