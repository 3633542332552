import { BarChartType, PieChartType, WaterfallChartType} from "../../types/charts";

export interface GraphState {
    selectedCategoryID: string[],
    selectedCountryID: Set<string>,
    selectedMediaType: MediaTypes | null,
    mediatypes: Map<MediaTypes, Mediatype>,
    selectedYears: {
        min: number,
        max: number,
    }
}

export enum ChartTypes {
    BarChart = "barGraph",
    LineChart = "lineChart",
    WaterfallChart = "waterfallGraph",
    PieChart = "pieGraph"
}

export interface Mediatype {
    type: MediaTypes
    isFetching: boolean,
    categories: Map<string, Categories>
}


export interface PieCahrtCategory {
    _id: string,
    order?:Number,
    title: string,
    type: ChartTypes.PieChart,
    charts: Map<string, PieChartType>
    isFetching: boolean,
    footnote?:string,
    headCategory?:string,
    subtitle?:string,
    countries: Map<string, Country>,
    hasDecimals:Number
}

export interface BarChartCategory {
    _id: string,
    title: string,
    type: ChartTypes.BarChart,
    charts: Map<string, BarChartType>,
    footnote?:string,
    headCategory?:string,
    order?:Number,
    isFetching: boolean,
    subtitle?:string,
    countries: Map<string, Country>,
    hasDecimals:Number,
    showCombined?:boolean,
    showCombinedAlternative?:boolean
}

export interface WaterfallChartCategory {
    _id: string,
    title: string,
    order?:Number,
    footnote?:string,
    type: ChartTypes.WaterfallChart,
    charts: Map<string, WaterfallChartType>
    isFetching: boolean,
    headCategory?:string,
    subtitle?:string,
    countries: Map<string, Country>,
}

export type Categories = BarChartCategory | WaterfallChartCategory | PieCahrtCategory;



export interface Chart {
    _id: string,
    countryID: string,
    mediatype: MediaTypes,
    country: string
}


export interface Country {
    _id: string,
    title: string
}

export enum MediaTypes {
    overview = "Overview",
    access = "Access",
    sound = "Audio",
    text = "Text",
    video = "Video"
    
    
    
    
}

export const SET_MEDIATYPE = "SET_MEDIATYPE";
export interface SetMediaTypeAction {
    type: typeof SET_MEDIATYPE,
    payload: Mediatype,
    meta: {
        mediatype: MediaTypes,
    }
}

export const SET_SELECTED_CATEGORY = "SET_SELECTED_CATEGORY"
export interface SetSelectedCategoryAction {
    type: typeof SET_SELECTED_CATEGORY,
    payload: Array<string>,
}

export const SET_SELECTED_COUNTRY = "SET_SELECTED_COUNTRY"
export interface SetSelectedCountryAction {
    type: typeof SET_SELECTED_COUNTRY,
    payload: Array<string>,
}

export const SET_SELECTED_MEDIATYPE = "SET_SELECTED_MEDIATYPE"
export interface SetSelectedMediatypeAction {
    type: typeof SET_SELECTED_MEDIATYPE,
    payload: MediaTypes,
}

export const SET_CATEGORY_FOR_MEDIATYPE = "SET_CATEGORY_FOR_MEDIATYPE";
export interface SetCategoryForMediaTypeAction {
    type: typeof SET_CATEGORY_FOR_MEDIATYPE
    payload: Categories,
    meta: {
        mediatype: MediaTypes
    }
}

export const SET_SELECTED_YEARS = "SET_SELECTED_YEARS";
export interface SetSelectedYearsAction {
    type: typeof SET_SELECTED_YEARS,
    payload: {
        min: number,
        max: number,
    }
}

export type GraphActionTypes = 
    | SetSelectedCategoryAction 
    | SetSelectedCountryAction
    | SetSelectedMediatypeAction
    | SetMediaTypeAction
    | SetCategoryForMediaTypeAction
    | SetSelectedYearsAction

