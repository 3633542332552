export function extractErrorFromResponse(response: any) {
    let error = '';

    if (response && response.details && response.details[0] ) {
        error = response.details[0].message
    }

    return error;
}


export function isAdmin(auth0) {
    const {user} = auth0;
    return user['https://mediavision.se/roles'].find(role => role.name === 'Admin')
}
