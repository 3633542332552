import {get, post, put,deleteReq} from "./restTemplate"

export function getChartsForCategory(category:any){
    return get(`/admin/graphforcategory?categoryId=${category}`, "asd")
}

export function createBarChart(data:any){
    return post(`/graphs/bar`,JSON.stringify(data), "asd")
}

export function updateBarChartData(data:any){
    return put(`/graphs/bar`, JSON.stringify(data), "asd")
}

export function updatePieChartData(data:any){
    return put(`/graphs/pie`, JSON.stringify(data), "asd")
}

export function deleteBarChart(id:any){
    return deleteReq(`/graphs/bar?id=${id}`, "asd")
}

export function deleteWaterfallChart(id:any){
    return deleteReq(`/graphs/waterfall?id=${id}`, "asd")
}

export function deletePieChart(id:any){
    return deleteReq(`/graphs/pie?id=${id}`, "asd")
}

export function createWaterfallChart(data:any){
    return post(`/graphs/waterfall`,JSON.stringify(data), "asd")
}

export function createPieChart(data:any){
    return post(`/graphs/pie`,JSON.stringify(data), "asd")
}


export function updateCategory(category:any){
    return put(`/categories`,JSON.stringify(category), "asd")
}



