import Modal from "@material-ui/core/Modal";
import {Button, Container, FormControl, Input, InputLabel} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {getModalStyle, useStyles} from "../../pages/AdminCategories/Styles";
import {deleteReportGroup, updateReportGroup} from "../../services/categoryService";
import {ReportGroupInterface} from "./interfaces";
import {Alert} from "@material-ui/lab";



export const EditReportGroupModal: React.FC<{ isOpen: boolean, setIsOpen: any, reportGroup: ReportGroupInterface, setReload: any }> = ({isOpen, setIsOpen, reportGroup, setReload}) => {
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();

    const [name, setName] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        setName(reportGroup.name);
    }, [reportGroup]);

    const destroyReport = () => {
        const reportData = {
            id: reportGroup._id
        };

        deleteReportGroup(reportData).then(async (res) => {
            const status = res.status ?? res.httpStatusCode;

            setIsOpen(false);
            setReload({});

            if (status === 200) {
                // setError('')
                // setEditing(false)
            }
        })
    }

    const submitReport = async () => {
        const reportId: string = reportGroup._id.toString()
        let reportData = new FormData()
        reportData.append('name', name)
        reportData.append('id', reportId)


        updateReportGroup(reportData).then(async (response) => {
            setReload({});
            let originResponse = response;
            let res = await response.json()

            if (res.details && res.details[0]?.message) {
                setError(res.details[0].message)
                return false;
            }

            if (res.httpStatusCode === 422) {
                setError(res.details[0]?.message)
                return false;
            }

            if (res.status === 422) {
                setError(res.statusText)
                return false;
            }

            if (res.errors && Object.keys(res.errors).length > 0) {
                setError(res.message)
                return false;
            }

            setName('');
            setError('');
            setIsOpen(false);
        }).catch(e => {
            console.log('err res', e)
        })
    }

    return <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={() => {
            setIsOpen(false)
        }}

    >
        <div style={modalStyle} className={classes.paper}>
            <Container>
                {error ?
                    <Alert className="mb-3" severity="error">{error}</Alert> : '' }

                <h3>Update report</h3>
                <FormControl className={classes.defFormControl}>
                    <InputLabel>Report name</InputLabel>
                    <Input value={name} onChange={(e) => setName(e.target.value)}/>
                </FormControl>

                <div className="button-block">

                    <Button color="secondary" variant="contained" onClick={destroyReport}>
                        Delete
                    </Button>
                    <Button color="primary" variant="contained" onClick={submitReport}>
                        Update
                    </Button>

                </div>


            </Container>
        </div>
    </Modal>

}


