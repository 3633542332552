import React from "react";


export const ReportFilter = ({filter, setFilter}) => {

    return <div className="report-filter">
        <p className={'small-text'}>Filter</p>
        <input type="text" value={filter} onChange={(e) => setFilter(e.target.value)} />
    </div>
}


