import React, { useEffect, useState  } from 'react';
import { Container, TextField, Button } from "@material-ui/core";
import {Card, CardContent} from '@material-ui/core/';
import {getCountries, createCountry, updateCountry, } from './../../services/countryService'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
     marginBottom: 12,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


const AdminCountries : React.FC = () => {
    const classes = useStyles();
    const [countries, setCountries] = useState([]);
    const [loading, setLoading] = useState(false);
    const [countryText, setCountryText] = useState("");


    const getCountriesRoutine = ()=> {
        setLoading(true)
        getCountries().then((res) =>{
            setCountries(res)
            setLoading(false)
           
        })  
    }

    const addCountry = () => {
        setLoading(true)
        createCountry(countryText).then((res) => {
            setCountryText("")
            getCountriesRoutine()
        })
    }

    useEffect(() => {
       getCountriesRoutine()
    }, [])



    return (
        <main className="page-admincountries">
            <Container>
                <h1>Dina länder</h1>
                {
                    countries.map((country:any) => {
                        return (
                            <Country country={country} />   
                        )
                    })
                }
            </Container>
            <Container>
                <h2>lägg till ett land</h2>
                <TextField value={countryText} onChange={(e) => setCountryText(e.target.value)} />
                <Button  onClick={addCountry}>
                    Lägg till land
                </Button>
              </Container>
        </main>
    )
}

const Country : React.FC<any> = (props) => {
    const [editing, setEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState(props.country.title)
    const classes = useStyles();

    const updateCountryRoutine = () => {
        setLoading(true)
        let country = props.country
        country.title = text
        updateCountry(country).then((res) => {
            setLoading(false)
            setEditing(false)
        })
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                {
                    editing ? 
                    (<div>
                        <TextField value={text} onChange={(e) => setText(e.target.value)} />
                        <Button  onClick={() => {setEditing(false)}}>
                            Avbryt
                        </Button>
                        <Button  onClick={() => {updateCountryRoutine()}}>
                            Uppdatera
                        </Button>
                    </div>) 
                    : 
                    (
                    <div>
                        <h3>{props.country.title}</h3> 
                        <Button onClick={() => setEditing(true)}>Edit</Button>
                    </div>
                    )
                }
            </CardContent>
        </Card>
    )
}


export default AdminCountries;
