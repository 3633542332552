import React, { useEffect, useState, useMemo  } from 'react';
import { Container, TextField, Button, Input, Card, CardContent} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { getStream} from './../../services/streamingService'
import {
    useParams
  } from "react-router-dom";

import _ from 'lodash';
const useStyles = makeStyles({

});



const StreamingOverview : React.FC = (props:any) => {

    const [stream, setStream] = useState({title: '', date: '', description: '', URL: ''});
    let params:any = useParams()

    useEffect(() => {
        getStream(params.id).then((res) => {
            console.log("stream", res)
            setStream(res)
        })
    }, [])

    if(!stream){
        return (<h1>loading</h1>)
    }

    return (
        <section className="single-card-inner">
            <div className="main-inner">
            {
            stream ? (
                <div className="content">
                    <h1>{stream.title}</h1>
                    <p className="date">{stream.date}</p>
                    <div className="video-container">
                        <iframe width="560" height="315" src={stream.URL}  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture; fullscreen"></iframe>
                    </div>
                    <p>{stream.description}</p>

                </div>
            ) : <h1>Loading</h1>
            }

            </div>
        </section>


    )

}

export default StreamingOverview;
