import Modal from "@material-ui/core/Modal";
import {Button, Container} from "@material-ui/core";
import React from "react";
import {getModalStyle, useStyles} from "../../pages/AdminCategories/Styles";
import {makeStyles} from "@material-ui/core/styles";
import checked from "../../Assets/images/checked.svg";

export const WideModal = ({isOpen, setIsOpen, text, icon}) => {
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();
    const localClasses = styles();


    return <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={() => {
            setIsOpen(false)
        }}

    >
        <div style={modalStyle} className={classes.widePopUpBlock}>
            <Container>
                <div className={localClasses.topLine}>
                    <a className={localClasses.noDecor} href="#"  onClick={() => setIsOpen(false)}>&times;</a>
                </div>
                <div className={localClasses.content}>
                    <img src={icon ? icon : checked } alt="success" />
                        <p className={localClasses.mainText}>{text}</p>
                    <label htmlFor="contained-button-file" className={localClasses.bottPad}>
                        <button className={localClasses.blackButton} onClick={() => setIsOpen(false)}>
                            OK
                        </button>
                    </label>
                </div>
            </Container>
        </div>
    </Modal>

}

export const styles = makeStyles(theme => ({
    topLine: {
        marginTop: "30px",
        display: "flex",
        justifyContent: "right",
    },

    mainText: {
        marginTop: "20px",
        marginBottom: "20px",
        fontSize: "18px",
        lineHeight: "22px",
        maxWidth: "800px",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
    },
    noDecor: {
        textDecoration: 'none',
        color: '#000',
        fontSize: "58px",
        lineHeight: "1px",
        marginTop: "15px",
        marginRight: "15px",

        "&:hover": {
            textDecoration: 'none',
            color: '#000',
        },
    },
    content: {
        marginTop: "20px",
        marginBottom: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        flexDirection: 'column',
    },
    blackButton: {
        backgroundColor: "#000",
        color: "#fff",
        padding: "5px 15px",
        borderRadius: "35px",
        width: "130px",
        fontSize: "13px",
        fontWeight: 700,
        height: "35px",
        textTransform: "uppercase",
        letterSpacing: "0.21em",
    },
    bottPad: {
        marginBottom: "30px",
    }
}));

