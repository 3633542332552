import React, {useState} from 'react';
import {useAuth0} from "../../react-auth0-spa";
import {CreateModal} from "../../components/Reports/createModal";
import {EditModal} from "../../components/Reports/editModal";
import {Loader} from "rsuite";
import {ReportTable} from "../../components/Reports/ReportTable";
import {ReportGroupTable} from "../../components/Reports/ReportGroupTable";
import {CreateReportGroupModal} from "../../components/Reports/createReportGroupModal";
import {EditReportGroupModal} from "../../components/Reports/editReportGroupModal";
import useReportGroupFetch from "../../components/Reports/reportGroupFetch";


const Reports: React.FC = () => {
    const {user} = useAuth0();
    const [isOpen, setIsOpen] = useState(false);
    const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
    const [editReportGroupModalIsOpen, setEditReportGroupModalIsOpen] = useState(false);
    const [editModalIsOpen, setEditModalIsOpen] = useState(false);
    const [overlay, setOverlay] = useState("");
    const [reload, setReload] = useState({});
    const [currentReportGroup, setCurrentReportGroup] = useState({
        name: ''
    });
    const [currentReport, setCurrentReport] = useState({
        report_name: '',
        report_group: '',
        publishing_date: '',
        period: ''
    });
    const {reportGroupLoading, reportGroups} = useReportGroupFetch(reload, user);

    return (
        <main className="page-admincategories" style={{marginTop: "100px"}}>
            {overlay !== '' ?
                <div className="reports-overlay" onClick={() => setOverlay('')}>
                    <div className="overlay-download">
                        <p>File is being prepared for download</p>
                        {overlay === 'loading' ? <Loader /> : <a  href={overlay}>Download</a>}
                    </div>
                </div> : ''}

                <ReportTable
                    setEditModalIsOpen={setEditModalIsOpen}
                    setIsOpen={setIsOpen}
                    setReload={setReload}
                    isOpen={isOpen}
                    reload={reload}
                    setCurrentReport={setCurrentReport}
                    setOverlay={setOverlay}
                />

                <ReportGroupTable
                    setEditModalIsOpen={setEditReportGroupModalIsOpen}
                    setOverlay={setOverlay}
                    isOpen={isGroupModalOpen}
                    setIsOpen={setIsGroupModalOpen}
                    setCurrentReport={setCurrentReportGroup}
                    data={reportGroups}
                    reportGroupLoading={reportGroupLoading}
                />


            <CreateModal isOpen={isOpen} setIsOpen={setIsOpen} setReload={setReload} reportGroups={reportGroups} />
            <CreateReportGroupModal isOpen={isGroupModalOpen} setIsOpen={setIsGroupModalOpen} setReload={setReload}/>

            <EditModal
                isOpen={editModalIsOpen}
                setIsOpen={setEditModalIsOpen}
                report={currentReport}
                setReload={setReload}
                reportGroups={reportGroups}
            />
            <EditReportGroupModal
                isOpen={editReportGroupModalIsOpen}
                setIsOpen={setEditReportGroupModalIsOpen}
                reportGroup={currentReportGroup}
                setReload={setReload}
            />
        </main>


    )
}

export default Reports;
