import React, { useState } from "react";
import Chart from "react-google-charts";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { BarChartType, xTypes, PopulationData } from "../../../types/charts";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "../../../store";
import Select from "../../forms/Select";
import MultipleSelect from "../../forms/MultipleSelect/MultipleSelect";
import { Button } from "@material-ui/core";
import { exportData } from "../../../services/exportService";
import { CloudDownload, SwapHoriz } from "@material-ui/icons";
import barIcon from "../../../Assets/images/bar.png";
import lineIcon from "../../../Assets/images/line.png";
import transposeIcon from "../../../Assets/images/transpose.png";
import exportIcon from "../../../Assets/images/export.png";
import { makeStyles } from "@material-ui/styles";
import { useAuth0 } from "./../../../react-auth0-spa";
import colorService from "../../../services/colorService";
import { MultiCascader, SelectPicker, CheckPicker } from "rsuite";
import color from "@material-ui/core/colors/amber";

const useStyles = makeStyles((theme) => ({
  widerForm: {
    minWidth: 150,
    maxWidth: 250,
  },
}));

export interface BarGraphProps {
  graphs: Array<BarChartType>;
  title: string;
  footnote?: string;
  showCombined?: boolean;
  showCombinedAlternative?: boolean;
  category?: any;
}

/* Remove '#' in color hex string */
function trim(s: any) {
  return s.charAt(0) == "#" ? s.substring(1, 7) : s;
}

function uniq(a: any) {
  return a.sort().filter(function (item: any, pos: any, ary: any) {
    return !pos || item != ary[pos - 1];
  });
}

function transpose(list: any) {
  var first = list.map((c: any) => c[0]);
  var lst = [];
  lst.push(first);
  for (var i = 1; i < list[0].length; i++) {
    lst.push(list.map((d: any) => d[i]));
  }
  return lst;
}

const maybeGetSingleCountry = (title: string) => {
  const isSE = title.startsWith('SE')
  const isNO = title.startsWith('NO')
  const isDK = title.startsWith('DK')
  const isFI = title.startsWith('FI')

  if(isSE) {
    return 'Sweden'
  }
  if(isNO) {
    return 'Norway'
  }
  if(isDK) {
    return 'Denmark'
  }
  if(isFI) {
    return 'Finland'
  }
  return false
}

const BarChart: React.FC<BarGraphProps> = ({
  graphs,
  title,
  category,
  footnote,
  showCombined,
  showCombinedAlternative,
}) => {

  const populationData = graphs[0].populationData
  const absRel = graphs[0].category.absRel
  const singleCountry = maybeGetSingleCountry(title)
  const { user } = useAuth0();

  const selectedYears = useSelector(
    (state: AppState) => state.graphs.selectedYears
  );
  const [typeOfGraph, setTypeOfGraph] = useState<any>("ColumnChart");

  const [labels, setLabels] = useState<string[]>([]);
  const [isTranspose, setIsTranspose] = useState(false);
  const [scaleToAbsolute, setScaleToAbsolute] = useState(false);

  const [showCombinedAns, setShowCombined] = useState(
    showCombined == undefined ? false : showCombined
  );

  const [showCombinedAlternativeAns, setShowCombinedAlternative] = useState(
    showCombinedAlternative == undefined ? false : showCombinedAlternative
  );
  const classes = useStyles();

  if (graphs.length === 0) {
    return <div></div>;
  }

  let colors: any = [];
  var data: any = [];
  if (!showCombinedAns) {
    graphs = graphs.sort((x: any, y: any) => {
      let countryX = x.country.title[0] + "";
      let countryY = y.country.title[0] + "";
      return countryX.localeCompare(countryY);
    });

    data = graphs.map((graph) => {
      return isTranspose
        ? transpose(
            BarGraphDataToChart(
              MergeBarGraphs([graph], selectedYears, compareDate, labels),
              graph.isPercentage,
              typeOfGraph,
              scaleToAbsolute,
              populationData,
              singleCountry,
              graph,
            )
          )
        : BarGraphDataToChart(
            MergeBarGraphs([graph], selectedYears, compareDate, labels),
            graph.isPercentage,
            typeOfGraph,
            scaleToAbsolute,
            populationData,
            singleCountry,
            graph,
          );
    });
  } else {
    data = isTranspose
      ? transpose(
          BarGraphDataToChart(
            MergeBarGraphs(graphs, selectedYears, compareDate, labels),
            graphs[0].isPercentage,
            typeOfGraph,
            scaleToAbsolute,
            populationData,
            singleCountry,
            graphs[0],
          )
        )
      : BarGraphDataToChart(
          MergeBarGraphs(graphs, selectedYears, compareDate, labels),
          graphs[0].isPercentage,
          typeOfGraph,
          scaleToAbsolute,
          populationData,
          singleCountry,
          graphs[0],
        );
  }
  data = orderLabels(data);
  if (!data || !data[0]) {
    return (
      <div>
        <p>There's no data for this chart</p>
      </div>
    );
  }

  // var columnCount = 0;
  // if (!showCombinedAns) {
  //   columnCount = data[0][0].length;
  // } else {
  //   columnCount = data[0].length;
  // }

  if (data[0].length > 2 && showCombinedAns) {
    colors = colorService(
      data[0].filter((x: any) => x !== " "),
      isTranspose
    );
  } else {
    if (data[0][0] && typeof data[0][0] !== "string") {
      let colorFilter = data[0][0].filter((x: any) => x !== " ");
      if (colorFilter.length === 0) {
        colorFilter = ["label"];
      }
      colors = colorService(colorFilter, isTranspose);
    } else {
      let colorFilter = data[0].filter((x: any) => x !== " ");
      if (colorFilter.length === 0) {
        colorFilter = ["label"];
      }
      colors = colorService(colorFilter, isTranspose);
    }
  }

  const options = {
    legend: {
      maxLines: 4,
      position: "top",
      scrollArrows: { inactiveColor: "#d0a768", activeColor: "#d0a768" },
      color: "#d0a768",
      pagingTextStyle: {
        color: "#d0a768",
      },
      pageIndex: 100,
    },
    pointSize: 10,
    colors: colors,
    animation: {
      startup: true,
      easing: "linear",
      duration: 700,
    },
    hAxis: {
      title: graphs[0].xLabel,
      format: graphs[0].isPercentage && !scaleToAbsolute ? "percent" : "",
      formatOptions: { groupingSymbol: " " },
      minValue: 0,
    },
    vAxis: {
      minValue: 0,
      title: scaleToAbsolute ? "'000" : graphs[0].yLabel,
      format: graphs[0].isPercentage && !scaleToAbsolute ? "percent" : "# ###",
    },
  };
  var pattern = "#";
  if (
    graphs[0].hasDecimals &&
    typeof graphs[0].hasDecimals === "number" &&
    graphs[0].hasDecimals > 0
  ) {
    pattern += ".";
    for (var i = 0; i < graphs[0].hasDecimals && i < 4; i++) {
      pattern += "#";
    }
  }
  pattern += "%";
  const percentageFormatter = {
    pattern: pattern,
    fractionDigits:
      graphs[0].hasDecimals && typeof graphs[0].hasDecimals === "number"
        ? graphs[0].hasDecimals
        : 0,
  };

  const decimalFormatter = {
    groupingSymbol: " ",
    fractionDigits:
      graphs[0].hasDecimals && typeof graphs[0].hasDecimals === "number"
        ? graphs[0].hasDecimals
        : 0,
  };

  let bars: any = [];
  graphs.map((graph) => {
    bars = bars.concat(
      graph.bars.map((bar) => {
        return bar.label;
      })
    );
  });

  let filter = uniq(bars)
    .map((bar: any) => {
      return { value: bar, label: bar };
    })
    .filter((x: any) => x.value.trim() !== "");

  return (
    <div className="barchart chart">
      <div className="chart-settings">
        <div className="chart-settings__select">
          {filter.length > 1 ? (
            <CheckPicker
              countable={false}
              style={{ width: 400, maxWidth: 400 }}
              data={filter}
              value={labels}
              placeholder={"Filter"}
              onChange={(e) => {
                setLabels(e);
              }}
            />
          ) : null}
        </div>
        <div className="chart-settings__button">
          <Button
            color="primary"
            onClick={() => {
              setTypeOfGraph(
                typeOfGraph === "ColumnChart" ? "LineChart" : "ColumnChart"
              );
            }}
          >
            <span className="button-icon">
              <img src={typeOfGraph === "ColumnChart" ? lineIcon : barIcon} />
            </span>
          </Button>
          <Button
            color="primary"
            onClick={() => {
              setIsTranspose(!isTranspose);
            }}
          >
            <span className="button-icon">
              <img src={transposeIcon} />
            </span>
          </Button>
          {graphs[0].isPercentage && absRel && (
            <Button
              color="primary"
              onClick={() => {
                setScaleToAbsolute(!scaleToAbsolute);
              }}
            >
              <span className="button-icon">
                {
                  scaleToAbsolute ? "%" : "'000"
                }
              </span>
            </Button>
          )}

          {graphs[0].exportable ? (
            <Button
              onClick={() => {
                exportData(
                  [
                    {
                      name: "data",
                      title: title,
                      subTitle: category.subtitle,
                      data: transpose(
                        BarGraphDataToChart(
                          MergeBarGraphs(
                            graphs,
                            selectedYears,
                            compareDate,
                            labels
                          ),
                          graphs[0].isPercentage,
                          typeOfGraph,
                          scaleToAbsolute,
                          populationData,
                          singleCountry,
                          graphs[0],
                        )
                      ),
                    },
                  ],
                  Boolean(graphs[0].isPercentage) && !scaleToAbsolute,
                  user

                ).then((res) => {
                  NotificationManager.success(
                    "Data sent to your registered email."
                  );
                });
              }}
            >
              <span className="button-icon">
                <img src={exportIcon} />
              </span>
            </Button>
          ) : null}
          {showCombinedAlternativeAns ? (
            <Button
              onClick={() => {
                setShowCombined(
                  showCombinedAns == undefined ? true : !showCombinedAns
                );
              }}
            >
              {showCombinedAns ? "Separate countries" : "Compare countries"}
            </Button>
          ) : null}
        </div>
      </div>
      {showCombinedAns ? (
        <>
          <Chart
            chartType={typeOfGraph}
            width="100%"
            height="300px"
            formatters={Array(data[0].length - 1)
              .fill(null)
              .map((val, i) => {
                return {
                  column: i + 1,
                  type: "NumberFormat",
                  options:
                    graphs[0].isPercentage && !scaleToAbsolute
                      ? percentageFormatter
                      : decimalFormatter,
                };
              })}
            data={data}
            options={options}
          />
          <p>{footnote}</p>
        </>
      ) : (
        data.map((d: any, i: any) => {
          var nOptions = JSON.parse(JSON.stringify(options));
          nOptions.vAxis.title = graphs[i].yLabel;
          nOptions.hAxis.title = graphs[i].xLabel;
          var colors;
          if (!d) {
            return;
          }

          d = orderLabels(d);
          let colorFilter = d[0].filter((x: any) => x !== " ");
          if (colorFilter.length === 0) {
            colorFilter = ["label"];
          }
          colors = colorService(colorFilter, isTranspose);

          nOptions.colors = colors;

          if (d[0] && d[0].length === 1) {
            return;
          }
          return (
            <>
              <h3>{graphs[i].country.title}</h3>

              <Chart
                chartType={typeOfGraph}
                width="100%"
                height="300px"
                formatters={Array(
                  showCombinedAns ? d.length - 1 : d[0].length - 1
                )
                  .fill(null)
                  .map((val, i) => {
                    return {
                      column: i + 1,
                      type: "NumberFormat",
                      options: (graphs[0].isPercentage && !scaleToAbsolute)
                        ? percentageFormatter
                        : decimalFormatter,
                    };
                  })}
                data={d}
                options={nOptions}
              />
              <p>{footnote}</p>

              <NotificationContainer />
            </>
          );
        })
      )}
    </div>
  );
};

const orderLabels = (data: any) => {

  function rearrange(rows: any, pos: any) {
    return rows.map(function (cols: any) {
      return pos.map(function (i: any) {
        return cols[i];
      });
    });
  }

  let isCountry = false;
  if (typeof data[0] !== "string" && data[0]) {
    data[0].map((ctr: any) => {
      if (!ctr || !ctr.toLowerCase) {
        return;
      }
      if (ctr.toLowerCase().includes("sweden")) {
        isCountry = true;
      }
      if (ctr.toLowerCase().includes("denmark")) {
        isCountry = true;
      }
      if (ctr.toLowerCase().includes("finland")) {
        isCountry = true;
      }
      if (ctr.toLowerCase().includes("norway")) {
        isCountry = true;
      }
    });
  }

  if(!isCountry){
    if(data.length > 1 ){

      let str = (((data[1])[0])+"").toLowerCase().split(" ")[0];
      if(str && (str === "finland" || str === "sweden" || str === "norway" || str === "denmark")){
        let rowToSort = JSON.parse(JSON.stringify(data))
        rowToSort.sort((a:any,b:any) => {
          return a[0].localeCompare( b[0])
        })
        return rowToSort
      }
    }
    return data
  }

  let rowToSort = JSON.parse(JSON.stringify(data[0]));
  rowToSort.shift();
  rowToSort = rowToSort.map((a: any, i: any) => {
    return { name: a, index: i };
  });
  rowToSort.sort((a: any, b: any) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });


  let sort = rowToSort.map((a: any) => a.index + 1);
  return rearrange(data.filter((x:any) => x), [0].concat(sort));
};

const BarGraphDataToChart = (
  bdata: BarChartType,
  isPercentage: any,
  typeOfGraph: string,
  scaleToAbsolute = false,
  populationData: PopulationData | undefined,
  singleCountry: string | false = false,
  graph: BarChartType
) => {
  const header = [bdata.xLabel, ...bdata.bars.map((bar) => bar.label)];
  let values = bdata.xValues.map((xval, index) => {
    return [
      (() => {
        if (bdata.xType === xTypes.Quarter) {
          const year = new Date(xval).getFullYear().toString();
          const month = (new Date(xval).getMonth() + 1)
            .toString()
            .padStart(2, "0");
          var quarter;

          switch (month) {
            case "01":
              quarter = "Q1";
              break;
            case "04":
              quarter = "Q2";
              break;
            case "07":
              quarter = "Q3";
              break;
            case "10":
              quarter = "Q4";
              break;
          }
          return quarter + " " + year;
        } else if (bdata.xType === xTypes.Year) {
          return new Date(xval).getFullYear().toString();
        } else {
          return xval;
        }
      })(),
      ...bdata.bars.map((bar) => {
        if (scaleToAbsolute && populationData) {
          let country = graph.country.title;
          const transformedBar = bar.label.split(" ")[0].toLowerCase()
          if(["denmark", "finland", "norway", "sweden"].includes(transformedBar)) {
            country = transformedBar;
          }
          const population = getPopulationData(populationData, new Date(xval), singleCountry ? singleCountry : country, bdata.xType)
          const scaled = (bar.yValues[index] || 0) * population
          return Math.round(scaled/5)*5;
        }
        return bar.yValues[index];
      }),
    ];
  });

  if (values.length == 0) {
    return false;
  }

  if (typeOfGraph === "BarChart") {
    values = values.reverse();
  }

  return [header, ...values];
};

const getPopulationData = (populationData: PopulationData, date: Date, country: string, xType: xTypes) => {

  const transformedCountry = country.split(" ")[0].toLowerCase()
  let data;
  switch(transformedCountry) {
    case 'sweden':
      data = populationData.SE
      break;
    case 'norway':
      data = populationData.NO
      break;
    case 'finland':
      data = populationData.FI
      break;
    case 'denmark':
      data = populationData.DK
  }

  if(!data) {
    return 0
  }

  const year = date.getFullYear()
  const yearData = data.find((item) => item.year === year)
  if(!yearData) {
    return 0
  }

  if(xType === xTypes.Year) {
    return yearData.total
  }

  if(xType === xTypes.Quarter) {
    const quarter = getQuarter(date)
    return yearData.quarter[quarter]
  }

  return 0
}

const getQuarter = (date: Date) : 'Q1' | 'Q2' | 'Q3' | 'Q4' => {
  const month = (date.getMonth() + 1)
    .toString()
    .padStart(2, "0");

  switch (month) {
    case "01":
      return 'Q1'
    case "04":
      return 'Q2'
    case "07":
      return 'Q3'
    case "10":
      return 'Q4'
  }
  return 'Q1'
}

export default BarChart;

export const MergeBarGraphs = (
  graphs: Array<BarChartType>,
  yearRange: { min: number; max: number },
  sortXValues?: (a: Date, b: Date) => any,
  labels?: any
): BarChartType => {
  if (graphs.length === 0) {
    throw new Error("Cannot merge zero grahps");
  }

  let bars = graphs
    .map((graph) => {
      const xvals = filterUniqueDate(
        graphs.map((graph) => graph.xValues).flat()
      )
        .filter((d) => {
          const dYear = new Date(d).getFullYear();
          if (dYear < yearRange.min || dYear > yearRange.max) {
            return false;
          }
          return true;
        })
        .sort(sortXValues)
        .map((date) => date.toString());

      return graph.bars.map((bar) => {
        return {
          yValues: xvals.map((xval) => {
            if (!graph.xValues.map((date) => date.toString()).includes(xval)) {
              return null;
            }

            return bar.yValues[
              filterUniqueDate(graphs.map((graph) => graph.xValues).flat())
                .sort(sortXValues)
                .map((date) => date.toString())
                .indexOf(xval)
            ];
          }),
          label:
            graphs.length > 1
              ? graph.country.title + " - " + bar.label
              : bar.label,
        };
      });
    })
    .flat();

  if (labels && labels.length > 0) {
    let newBars: any = [];
    labels.map((label: any) => {
      bars.map((bar: any) => {
        if (graphs.length > 1) {
          if (bar.label.split("- ")[1] === label) newBars.push(bar);
        } else {
          if (bar.label === label) {
            newBars.push(bar);
          }
        }
      });
    });
    bars = newBars;
  }

  return {
    _id: graphs.map((graph) => graph._id).join(" "),
    title: graphs[0].title,
    xLabel: graphs[0].xLabel,
    xType: graphs[0].xType,
    hasDecimals: graphs[0].hasDecimals,
    yLabel: graphs[0].yLabel,
    isPercentage: graphs[0].isPercentage,
    xValues: filterUniqueDate(graphs.map((graph) => graph.xValues).flat())
      .filter((d) => {
        const dYear = new Date(d).getFullYear();
        if (dYear < yearRange.min || dYear > yearRange.max) {
          return false;
        }

        return true;
      })
      .sort(sortXValues),
    country: graphs[0],
    bars: bars,
    populationData: graphs[0].populationData,
    category: graphs[0].category,
  };
};

function filterUniqueDate(data: Array<Date>) {
  const lookup = new Set();

  return data.filter((date) => {
    date = new Date(date);
    const serialised = date.getTime();
    if (lookup.has(serialised)) {
      return false;
    } else {
      lookup.add(serialised);
      return true;
    }
  });
}

const compareDate = (date1: Date, date2: Date) => {
  let d1 = new Date(date1);
  let d2 = new Date(date2);

  let same = d1.getTime() === d2.getTime();
  if (same) return 0;

  if (d1 > d2) return 1;

  if (d1 < d2) return -1;
};
