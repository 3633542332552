import React, { useEffect,useState } from 'react';
import { useAuth0 } from "./react-auth0-spa";
import Router from './Router';
import 'rsuite/dist/styles/rsuite-default.css';
import 'react-notifications/lib/notifications.css';

import './Assets/scss/main.scss';
import './Assets/less/rsuite.css';

const App: React.FC = () => {
  const { loading } = useAuth0();
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { getTokenSilently,user } = useAuth0();

  const [localDone, setLocal] = useState(false);

  useEffect(() => {
    if(!isAuthenticated && !loading) {
      loginWithRedirect({})
    }
  }, [isAuthenticated, loading])


  if(isAuthenticated){
        const token =  getTokenSilently();
        token.then((res:any) => {
          localStorage.setItem('token', res);
          setLocal(true)
        })
  }

  if (loading || !localDone) {
    return <div>Loading...</div>;
  }


  return (
    <div>
      {!isAuthenticated && (
        <></>
      )}

      {isAuthenticated &&
        <>
          <Router />
        </>
      }
    </div>
  );


}

export default App;
