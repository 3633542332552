import React, {useState, useEffect} from "react";
import { useAuth0 } from "../../react-auth0-spa";
import denied from "../../Assets/images/denied.svg";
import {WideModal} from "../../components/Reports/wideModal";
import {kpiFailedText} from "../FrontPage/FrontPage";
import Graphs from "./Graphs";

export const GraphsLauncher: React.FC = () => {
    const { user } = useAuth0();
    const [isOpen, setIsOpen] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const roles = user['https://mediavision.se/roles'];

    useEffect(() => {
        if (roles.length > 1) {
            setShowContent(true)
        } else {
            setIsOpen(true)
        }
    }, [roles])


    const closeModal = () => {
        setIsOpen(false);
        window.location.href = "/";
    }


    return (
        <main >

            {showContent ?
                <Graphs /> :
                <WideModal
                    isOpen={isOpen}
                    setIsOpen={closeModal}
                    text={kpiFailedText}
                    icon={denied}
                />
            }


        </main>
    )
}



