import {Table} from "react-bootstrap";
import React, { useState} from "react";
import useReportFetch from "./reportFetch";
import {GetReportRowsForUser} from "./getReportsRowsForUser";
import {useAuth0} from "../../react-auth0-spa";


export const ReportTableSimplified = ({filter, setIsOpen, setIsOpenKPIFailed}) => {
    const [reload, setReload] = useState({});
    const {user} = useAuth0();
    const {loading, data} = useReportFetch(reload, user);
    const rows = GetReportRowsForUser(data, filter, setIsOpen, setIsOpenKPIFailed);

    return <Table striped hover className="reports-table" style={styles.tableStyle}>
        <thead style={styles.tableHeader}>
        <tr  >
            <th style={styles.left}>
                <div style={styles.tableDiv}>Product</div>
            </th>
            <th style={styles.left}>
                <div style={styles.tableDiv}>Publishing date</div>
            </th>
            <th >Download PDF</th>
            <th >Access KPIs</th>
        </tr>
        </thead>
        <tbody style={styles.tableBody}>
        {loading || rows.length === 0 ? <tr key='empty-row'><td colSpan={4}>No current analyses</td></tr> : rows}
        </tbody>
    </Table>
}



const styles = {
    tableStyle: {
        marginTop: "30px",
        textAlign: "center" as const,
        fontSize: "18px",
    },
    tableHeader: {
        backgroundColor: "#922965",
        color: "#fff",
    },
    tableBody: {
        borderTop: 'none',
        verticalAlign: "middle",
    },
    tableBodyRow: {
        height: "40px",
    },
    left: {
        textAlign: "left" as const,
    },
    tableDiv: {
        width: "95%",
        marginLeft: "auto" as const,
        textAlign: "left" as const,
    },

};
