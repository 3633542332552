import React, { useEffect, useState, useMemo  } from 'react';
import { Container, TextField, Button, Input, Card, CardContent} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import {resetPass} from '../services/userService'
import { Link } from 'react-router-dom';
import _ from 'lodash';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
     marginBottom: 12,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


interface Role {
  countrySet: string,
  premiumUpdated: string,
  title: string,
  updated: string
}
const FAQ : React.FC = () => {
    const classes = useStyles();
    const [ faq, setFaq] = useState<string>("");
    const [ reset, setRest] = useState<boolean>(false);

    useEffect(() => {
        fetch("https://mediavision.se/wp-json/acf/v2/options",  {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json'
          },
        }).then((res:any) => {
          return res.json();
        }).then((a:any) => {
            setFaq(a.acf.faq)
         
    
        })
      }, [])

      let resetPassword = () => {
        resetPass()
        setRest(true)
      }

    
    return (
        <section className="frontpage">
            <div className="page_header" style={{background:"linear-gradient(0deg, rgba(218, 204, 193, 0.77), rgba(246, 243, 241, 0.75)), url(https://www.mediavision.se/wp-content/uploads/2019/12/photo-1526908234827-dcf46d772c3c.png)"}}>
                <div className="container">
                    <h1 className="page_title">METHOLOLGY &amp; FAQ</h1>
                </div>
            </div>
            <div style={{padding:15, maxWidth: 1140, marginLeft:"auto", marginRight:"auto"}} dangerouslySetInnerHTML={{__html: faq}} />
       

            
            
        </section>
        
    )
}

export default FAQ;
