import {SetSelectedCategoryAction, SET_SELECTED_CATEGORY, SetSelectedCountryAction, SET_SELECTED_COUNTRY, SetSelectedMediatypeAction, MediaTypes, SET_SELECTED_MEDIATYPE, Mediatype, SetMediaTypeAction, SET_MEDIATYPE, SetCategoryForMediaTypeAction, SET_CATEGORY_FOR_MEDIATYPE, Categories, SetSelectedYearsAction, SET_SELECTED_YEARS } from "./types";


export function setSelectedCategory(id : Array<string>) : SetSelectedCategoryAction {
    return {
        type: SET_SELECTED_CATEGORY,
        payload: id,
    }
}

export function setSelectedCountry(id : Array<string>) : SetSelectedCountryAction {
    return {
        type: SET_SELECTED_COUNTRY,
        payload: id
    }
}

export function setSelectedMediatype(mediatype: MediaTypes) : SetSelectedMediatypeAction {
    return {
        type: SET_SELECTED_MEDIATYPE,
        payload: mediatype
    }
}

export function setMediaType(key: MediaTypes, mediatype: Mediatype): SetMediaTypeAction {
    return {
        type: SET_MEDIATYPE,
        payload: mediatype,
        meta: {
            mediatype: key
        }
    }
}


export function setCategoryForMediaType(mediatype: MediaTypes, category: Categories) : SetCategoryForMediaTypeAction {
    return {
        type: SET_CATEGORY_FOR_MEDIATYPE,
        payload: category,
        meta: {
            mediatype: mediatype
        }
    }
}

export function setSelectedYears(min: number, max: number) : SetSelectedYearsAction {
    return {
        type: SET_SELECTED_YEARS,
        payload: {
            min: min,
            max: max,
        }
    }
}