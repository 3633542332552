import React, { useEffect, useState  } from 'react';
import { Container, TextField, Button } from "@material-ui/core";
import {Card, CardContent} from '@material-ui/core/';
import { getHeadCategories, createHeadCategory, getTopHeadCategories, createTopHeadCategory, updateTopHeadCategory, deleteTopHeadCategory } from './../../services/categoryService'
import { makeStyles } from '@material-ui/core/styles';
import {TopHeadCategory} from "./TopHeadCategory";

const useStyles = makeStyles({
  card: {
    minWidth: 275,
     marginBottom: 12,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


const AdminCategory : React.FC = () => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [categoryText, setCategoryText] = useState("");
    const [topHeadCategories, setTopHeadCategories] = useState([])


    const getTopHeadCategoriesR = () => {
        getTopHeadCategories().then((res:any) =>{
            setTopHeadCategories(res)
        })
    }

    const addHeadCategory = () => {
        setLoading(true)
        createTopHeadCategory(categoryText).then(() => {
            setCategoryText("")
            getTopHeadCategoriesR()
        })
    }

    useEffect(() => {
        getTopHeadCategoriesR()
    }, [])

    return (
        <main className="page-admincountries">
            <Container>
                <h1>Dina kategorier</h1>
                {
                    topHeadCategories.map((category:any) => {
                        return (
                            <TopHeadCategory category={category} reload={getTopHeadCategoriesR} />
                        )
                    })
                }
            </Container>
            <Container>
                <h2>lägg till top head category</h2>
                <TextField value={categoryText} onChange={(e) => setCategoryText(e.target.value)} />
                <Button  onClick={addHeadCategory}>
                    Add
                </Button>
              </Container>
        </main>
    )
}



export default AdminCategory;
