import React, {useState, useEffect, createContext} from "react";
import { useAuth0 } from "../../react-auth0-spa";
import {HeaderBanner} from "../../components/layout/headerBanner";
import {DisplayReportBlock} from "../../components/Reports/DisplayReportBlock";
import {WideModal} from "../../components/Reports/wideModal";
import denied from "../../Assets/images/denied.svg";
export const ThemeContext = createContext({user: null});

export const kpiFailedText = "Currently, you do not have access to KPIs from this analysis. \n" +
    "Please refer to the person in charge of the Mediavision contract at your company to get access.\n";

const FrontPage: React.FC = () => {
  const [ welcomeTitle, setWelcomTitle] = useState<string>("");
  const [welcomeText, setWelcomeText] = useState<string>("");
  const [ methodologyTitle, setMethodologyTitle] = useState<string>("");
  const [ methodologyText, setMethodologyText] = useState<string>("");
  const [ lastUpdated, setLastUpdated] = useState<string>("");
  const [firstImage, setFirstImage] = useState<string>("");
  const [secondImage, setSecondImage] = useState<string>("");
  const [ contactTitle, setContactTitle] = useState<string>("");
  const [ contactText, setContactText] = useState<string>("");
  const [ isOpen, setIsOpen] = useState<boolean>(false);
  const [ isOpenKPIFailed, setIsOpenKPIFailed] = useState<boolean>(false);
  const auth = useAuth0();

  useEffect(() => {
    fetch("https://mediavision.se/wp-json/acf/v2/options",  {
      method: 'GET',
      headers: {
          'Content-Type': 'application/json'
      },
    }).then((res:any) => {
      return res.json();
    }).then((a:any) => {
      setWelcomeText(a.acf.welcome_title + " " +  auth.user.name);
      setWelcomTitle(a.acf.welcome_title);

      setContactTitle(a.acf.contact_title);
      setContactText(a.acf.contact_text);

      setMethodologyTitle(a.acf.methodology_title);
      setMethodologyText(a.acf.methodology_text);

      setLastUpdated(a.acf.last_updated)

      setFirstImage(a.acf.portal_images)
      setSecondImage(a.acf.second_image)

    })
  }, [])
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  const headerData = {welcomeTitle, firstImage, user}
  const closeModalText = "Your personal, watermarked copy of this analysis will be sent to your email shortly."


  return (
      <ThemeContext.Provider value={{user: auth.user}}>
          <section className="frontpage">

              <div style={styles.wrapper}>
                  <HeaderBanner  welcomeText={welcomeText} />
                  <DisplayReportBlock setIsOpen={setIsOpen} setIsOpenKPIFailed={setIsOpenKPIFailed}/>
                  {/*<Footer />*/}
              </div>

              <WideModal
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  text={closeModalText}
                  icon={null}
              />

              <WideModal
                  isOpen={isOpenKPIFailed}
                  setIsOpen={setIsOpenKPIFailed}
                  text={kpiFailedText}
                  icon={denied}
              />
          </section>
      </ThemeContext.Provider>


  );
};

const styles = {
    wrapper: {
        display: "flex",
        // padding: "0 2em",
        // border: "1px solid red",
        alignItems: "center",
        flexDirection: 'column' as const,
        minHeight: "1100px",
    },

};

export const FrontPageHeader: React.FC = () => {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();
    let isAdmin = false
    user["https://mediavision.se/roles"].map((a:any) => {
        if(a.name==="Admin"){
            isAdmin=true
        }
    })

  return (
    <header className="frontpage_header">
        <div className="header-wrapper">
            <div className="brand">
                <a href="/">
                    <img src="https://mediavision.se/wp-content/uploads/2020/04/insights-2-e1587471990786.png" className="logo" />
                </a>
            </div>
            <div className="menu-wrapper" id="menu">
                <div className="menu-header-menu-container">
                  <ul id="menu-header-menu" className="menu">
                    <li id="menu-item-23" className={"menu-item menu-item-type-post_type menu-item-object-page menu-item-23 " +  (window.location.pathname === "/" ? "active" : "")}><a href="/">home</a></li>
                    <li id="menu-item-23" className={"menu-item menu-item-type-post_type menu-item-object-page menu-item-23 " + (window.location.pathname === "/data" ? "active" : "")}><a href="/data">ACCESS DATA</a></li>
                    <li id="menu-item-23" className={"menu-item menu-item-type-post_type menu-item-object-page menu-item-23 " + (window.location.pathname === "/faq" ? "active" : "")}><a href="/faq">METHOD</a></li>
                    {isAdmin ?
                        <li id="menu-item-24" className={"menu-item menu-item-type-post_type menu-item-object-page menu-item-24 " + (window.location.pathname === "/reports" ? "active" : "")}><a href="/admin/reports">REPORTS</a></li>
                    : "" }
                    <li id="menu-item-20" className="menu-item menu-item-type-post_type menu-item-object-page menu-item-20">
                        <a onClick={() => {logout({ returnTo: window.location.origin })}}>
                            Log out
                        </a>
                    </li>
                  </ul>
                </div>
            </div>
        </div>
    </header>
  );
};

export default FrontPage;
