import React, { useEffect, useMemo, useState } from 'react';
import MultipleSelect from '../../components/forms/MultipleSelect/MultipleSelect';
import Select from '../../components/forms/Select';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedMediatype, setSelectedCategory, setSelectedCountry, setMediaType, setCategoryForMediaType, setSelectedYears } from '../../store/Graphs/actions';
import { getHeadCategories, getTopHeadCategories, getMediaTypes } from './../../services/categoryService'
import { MultiCascader, SelectPicker, CheckPicker, RangeSlider } from 'rsuite';
import { MediaTypes, Categories, ChartTypes, Country, Mediatype } from '../../store/Graphs/types';
import { AppState } from '../../store';
import { getCategoriesForMediaType, getGraphsForCategory } from '../../services/userService';
import { CircularProgress, Typography, Slider, makeStyles, Chip, Divider, Collapse, IconButton } from '@material-ui/core';
import * as _ from 'lodash'
import TuneIcon from '@material-ui/icons/Tune';


const useStyles = makeStyles({
    sliderContainer: {
        marginLeft: 20
    },
    sliderTitle: {
        marginTop: -6,
        fontSize: 14,
        marginLeft: -4
    },
    slider: {
      width: 200,
    },
  });


const Filters : React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const selectedMediaType = useSelector((state: AppState) => state.graphs.selectedMediaType);
    const selectedCategory = useSelector((state: AppState) => state.graphs.selectedCategoryID);
    const selectedCountry = useSelector((state: AppState) => state.graphs.selectedCountryID);
    const mediatypes = useSelector((state: AppState) => state.graphs.mediatypes);
    const selectedYears = useSelector((state: AppState) => state.graphs.selectedYears);
    const [headCategories, setHeadCategories] = useState();
    const [topHeadCategories, setTopHeadCategories] = useState();
    const [selectableMediatypes, setSelectableMediatypes] = useState([]);


    const yearRange = useMemo(() => getDateRange(mediatypes, selectedMediaType, selectedCategory, selectedCountry), [mediatypes, selectedMediaType, selectedCategory, selectedCountry]);

    const [collapseOpen, setCollapseOpen] = useState(false);
    useEffect(() => {
        getMediaTypes().then((r) => {
            setSelectableMediatypes(r)
        })
        if(!selectedMediaType) {
            dispatch(setSelectedMediatype(MediaTypes.overview))
        }
    }, [])


    useEffect(() => {
        if(!selectedCategory) {
            return;
        }

        if(!selectedMediaType) {
            return;
        }
        const selectedMediatypeObj = mediatypes.get(selectedMediaType);
        if(!selectedMediatypeObj) {
            return;
        }

        const categories = selectedMediatypeObj.categories;

        const ids = Array.from(categories.values()).map(cat => {
            return cat._id
        })


        //dispatch(setSelectedCategory([ids[0]] as string[]))


    }, [mediatypes, selectedMediaType, dispatch])

    useEffect(() => {
        if(!yearRange.min || !yearRange.max) {
            return;
        }
        dispatch(setSelectedYears(
            yearRange.max - 2,
            yearRange.max
        ));

    }, [mediatypes, selectedMediaType])

    const handleChange = (newValue: number[]) => {
        if((newValue as number[]).length < 2) {
            return;
        }
        dispatch(setSelectedYears((newValue as number[])[0], (newValue as number[])[1]))
    };

    useEffect(() => {
        const newToFetch = Array.from(mediatypes.values()).filter((mediatype) => mediatype.isFetching);

        Promise.all(newToFetch.map(mediatype => {

            return getCategoriesForMediaType(mediatype.type).then(res => {
                const catMap = new Map<string, Categories>();

                res.categories.forEach((cat : any) => {
                    catMap.set(cat._id, {
                        _id: cat._id,
                        title: cat.title,
                        type: cat.barType,
                        hasDecimals:cat.hasDecimals,
                        footnote: cat.footnote,
                        charts: new Map(),
                        isFetching: true,
                        countries: new Map(),
                        headCategory: cat.headCategory,
                        order:cat.order,
                        ...cat
                    })
                })

                dispatch(setMediaType(mediatype.type, {
                    ...mediatype,
                    isFetching: false,
                    categories: catMap
                }))

            })
        })).then(fetchedData => {
            getHeadCategories().then((res:any) => {
                // @ts-ignore  Surpressing until its sure how it should work
                let headCatMap = new Map<string, any>();
                res.map((headCat:any) => {
                    headCatMap.set(headCat._id, headCat);
                })

                // @ts-ignore
                setHeadCategories(headCatMap)

            })

            getTopHeadCategories().then((res:any) => {

                let topHeadMap = new Map<String,any>();
                res.map((topHead:any) => {
                    topHeadMap.set(topHead._id, {title:topHead.title, order:topHead.order});

                })

                // @ts-ignore
                setTopHeadCategories(topHeadMap)
            })

        })

    }, [selectedMediaType, mediatypes])

    useEffect(() => {
        if(!selectedMediaType) {
            return;
        }
        const selectedMediatypeObj = mediatypes.get(selectedMediaType);
        if(!selectedMediatypeObj) {
            return;
        }

        const categoriesToFetch = Array.from(selectedCategory).map(catID => {
            return selectedMediatypeObj.categories.get(catID);
        }).filter((cat) => {
            if(!cat) {
                return false
            }

            return cat.isFetching;
        })


        Promise.all(categoriesToFetch.map(category => {
            if(!category) {
                return;
            }
            return getGraphsForCategory(selectedMediaType, category._id).then(resp => {


                const catObj = selectedMediatypeObj.categories.get(category._id);
                if(!catObj) {
                    return;
                }



                let countriesMap = new Map<string, Country>();
                Object.keys(resp.countries).map(id => {
                    countriesMap.set(id, resp.countries[id]);
                })

                const newCat = {
                    ...catObj,
                    countries:countriesMap,
                    charts: resp.graphs,
                    isFetching: false,
                }

                dispatch(setCategoryForMediaType(selectedMediaType, newCat))
            })
        }))

    }, [selectedCategory, mediatypes])


    function getData(){

                                //headCategories => är en Map med key = id och value = namn

                                if(!selectedMediaType) {
                                    return []
                                }
                                const selectedMediatypeObj = mediatypes.get(selectedMediaType);
                                if(!selectedMediatypeObj) {
                                    return [];
                                }

                                const categories = selectedMediatypeObj.categories;

                                const groupedCats = _.groupBy(Array.from(categories.values()), "headCategory");

                                if(!headCategories) {
                                    return [];
                                }
                                // @ts-ignore
        const groupedWithHeads = Array.from(headCategories).map((v : any) => {
                                    let children = groupedCats[v[0]] ? groupedCats[v[0]].map((cat:any) => {
                                        return {
                                            order:cat.order,
                                            value: cat._id,
                                            label: cat.title,
                                        }
                                    }) : []

                                    children.sort((a,b) => {
                                        let orderA = a.order ? a.order : 0
                                        let orderB = b.order ? b.order : 0
                                        return orderA-orderB
                                    })
                                    return {
                                        value: v[0],
                                        label: v[1].title,
                                        topHead:v[1].topHeadCategory,
                                        order:v[1].order,
                                        children: children
                                    }
                                }).filter((c) => c.children.length>0)
                                if(!topHeadCategories){
                                    return []
                                }

                                groupedWithHeads.sort((a:any, b:any) => {
                                    let orderA = a.order ? a.order : 0
                                        let orderB = b.order ? b.order : 0
                                        return orderA-orderB
                                })

                                 // @ts-ignore
        let arr=   Array.from(topHeadCategories).map((topHeadObj:any) => {
                                    return {
                                            value:topHeadObj[0],
                                            label: topHeadObj[1].title,
                                            order:topHeadObj[1].order,
                                            children: groupedWithHeads.filter((g) => g.topHead === topHeadObj[0])
                                        }
                                    }).filter((a:any) => a.children.length > 0 )
                                 arr.sort((a:any,b:any) => {
                                    let orderA = a.order ? a.order : 0
                                        let orderB = b.order ? b.order : 0
                                        return orderA-orderB
                                 })

                                 return arr
    }



    let data:any = getData();
    let notPick:any = []

    data.map((d:any) => {
        notPick.push(d.value)
        d.children.map((e:any) => {
            notPick.push(e.value)
        })
    })

    return (
        <div className="filters">
            <div className="filters__row">
                <div className="filters__select">
                    <SelectPicker
                        cleanable={false}
                        data={
                            selectableMediatypes.map((x:any) =>({
                                value: Object(MediaTypes)[x],
                                label:  Object(MediaTypes)[x]
                            }))

                        }
                        style={{

                                minWidth: 150,
                                maxWidth: 250,

                        }}
                        searchable={false}
                        onChange={(val) => {

                            dispatch(setSelectedMediatype(val))
                        }}
                        value={selectedMediaType ? String(selectedMediaType) : String(MediaTypes.video)}
                        placeholder={"Media type"}
                        disabled={Array.from(mediatypes.values()).some((mediatype) => mediatype.isFetching)}
                    />
                </div>
                <div className="filters__select">
                    <MultiCascader
                    uncheckableItemValues={notPick}
                    countable={false}
                    menuWidth={Math.round(700/3)}
                    style={{width:700, maxWidth:700}}
                    data={data}
                        value={selectedCategory}
                        placeholder={"Select KPI"}
                        onSelect={(e) => {

                            if(!e.children){

                                let oldCharts:any = JSON.parse(JSON.stringify(selectedCategory))
                                if(oldCharts.indexOf(e.value) === -1){
                                    oldCharts.unshift(e.value)
                                } else {
                                    oldCharts = oldCharts.filter((x:any) => x!== e.value)
                                }


                                dispatch(setSelectedCategory(oldCharts))
                            }
                        }}
                        onChange={(e) => {

                            let oldCharts:any = JSON.parse(JSON.stringify(selectedCategory))
                            let difference = e.filter((x:any) => !oldCharts.includes(x));
                            oldCharts = oldCharts.filter((x:any) => e.includes(x))
                            dispatch(setSelectedCategory(difference.concat(oldCharts)))
                        }}
                    />

                </div>
                <div className="filters__select">
                    <p style={{color:"black"}}>Period:</p>
                <RangeSlider
                            onChange={handleChange}
                            value={[selectedYears.min, selectedYears.max]}
                            min={yearRange.min ? yearRange.min : selectedYears.min}
                            max={yearRange.max ? yearRange.max : selectedYears.max}
                            className={classes.slider}
                            valueLabelDisplay="auto"
                            aria-labelledby="year-slider"
                            disabled={
                                (() => {

                                    if(!yearRange.min || !yearRange.max) {
                                        return true;
                                    }
                                    if(!selectedMediaType) {

                                        return true;
                                    }
                                    const selectedMediatypeObj = mediatypes.get(selectedMediaType);
                                    if(!selectedMediatypeObj) {
                                        return true;
                                    }

                                    if(Array.from(mediatypes.values()).some((mediatype) => mediatype.isFetching)) {
                                        return true;
                                    }

                             //       if(selectedCategory.length === 0) {
                               //         return true;
                                 //   }


                                const catFetching = Array.from(selectedCategory).map((cat) => {
                                        const catObj = selectedMediatypeObj.categories.get(cat);
                                        if(!catObj) {
                                            return true;
                                        }

                                        if(catObj.isFetching) {
                                            return true;
                                        }

                                        return false;
                                    }).some(bool => bool)

                                    return catFetching;
                                })()
                            }
                        />
                    </div>
                <div className="filters__chips" style={{display: "none"}}>
                    {
                        Array.from(selectedCategory).map((cat:string) => {
                            if(!selectedMediaType){
                                return
                            }
                            const selectedMediatypeObj = mediatypes.get(selectedMediaType);
                            if(!selectedMediatypeObj){
                                return
                            }
                            const categories = selectedMediatypeObj.categories;

                            if(!categories ){
                                return
                            }

                            let asd = categories.get(cat)
                            if(!asd){
                                return
                            }
                            return (
                                <div className="filters__chip"   key={asd.title}>
                                    <Chip
                                        key={asd.title}
                                        label={asd.title}
                                        onDelete={() => {
                                            //selectedCategory.delete(cat)

                                            dispatch(setSelectedCategory(selectedCategory.filter((c) => c !== cat)))
                                        }}
                                    />
                                </div>
                            )
                        })
                    }
                </div>

                {/* <div className="filters__tune">
                <IconButton size="small" style={{border:"1px solid #e5e5ea", borderRadius:6}} onClick={() => {setCollapseOpen(!collapseOpen)}}>
                         <TuneIcon />
                        <p style={{color:"#575757", marginLeft:4}}>Filter</p>
                     </IconButton>
                </div> */}



                <div className="filters__spinner">
                    {
                        Array.from(mediatypes.values()).some((mediatype) => mediatype.isFetching) && selectedMediaType && (
                            <div className="filters__spinnerinner">
                                <CircularProgress size={20} />
                            </div>
                        )
                    }
                </div>
            </div>
            <Collapse in={collapseOpen} timeout="auto" unmountOnExit className="filters__container" style={{display: Array.from(selectedCategory).length === 0 ? "none" :"block" }}>
                <h4 className="filters__subheader">Filter data</h4>
                <div className="filters__row">
                    <div className="filters__select" style={{display: Array.from(selectedCategory).length === 0 ? "none" :"block" }}>
                            <CheckPicker
                                searchable={false}
                                data={(() => {
                                    if(!selectedMediaType) {
                                        return []
                                    }
                                    const selectedMediatypeObj = mediatypes.get(selectedMediaType);
                                    if(!selectedMediatypeObj) {
                                        return [];
                                    }

                                    let countryMap = new Map<string, Country>();

                                    Array.from(selectedCategory).forEach((cat) => {
                                        const catObj = selectedMediatypeObj.categories.get(cat);
                                        if(!catObj) {
                                            return;
                                        }
                                        countryMap = new Map<string, Country>([...Array.from(countryMap), ...Array.from(catObj.countries)])

                                    })

                                    return Array.from(countryMap.values()).map(country => {
                                        return {
                                            label: country.title,
                                            value: country._id,
                                        }
                                    });

                                })()}
                                value={selectedCountry ? Array.from(selectedCountry) : undefined}
                                placeholder={"Country"}
                                onChange={(val) => {
                                    dispatch(setSelectedCountry(val as string[]))
                                }}
                                disabled={(() => {
                                    if(!selectedMediaType) {
                                        return true;
                                    }
                                    const selectedMediatypeObj = mediatypes.get(selectedMediaType);
                                    if(!selectedMediatypeObj) {
                                        return true;
                                    }

                                    if(Array.from(mediatypes.values()).some((mediatype) => mediatype.isFetching)) {
                                        return true;
                                    }

                                  //  if(selectedCategory.length == 0) {
                                    ///    return true;
                                   // }


                                const catFetching = Array.from(selectedCategory).map((cat) => {
                                        const catObj = selectedMediatypeObj.categories.get(cat);
                                        if(!catObj) {
                                            return true;
                                        }

                                        if(catObj.isFetching) {
                                            return true;
                                        }

                                        return false;
                                    }).some(bool => bool)

                                    return catFetching;
                                })() }
                            />
                        </div>
                        <div className={classes.sliderContainer} style={{display: Array.from(selectedCategory).length == 0 ? "none" :"block" }}>
                        <Typography className={classes.sliderTitle}>
                            Period:
                        </Typography>
                        <RangeSlider
                            onChange={handleChange}
                            value={[selectedYears.min, selectedYears.max]}
                            min={yearRange.min ? yearRange.min : selectedYears.min}
                            max={yearRange.max ? yearRange.max : selectedYears.max}
                            className={classes.slider}
                            valueLabelDisplay="auto"
                            aria-labelledby="year-slider"
                            disabled={
                                (() => {
                                    if(!yearRange.min || !yearRange.max) {
                                        return true;
                                    }
                                    if(!selectedMediaType) {
                                        return true;
                                    }
                                    const selectedMediatypeObj = mediatypes.get(selectedMediaType);
                                    if(!selectedMediatypeObj) {
                                        return true;
                                    }

                                    if(Array.from(mediatypes.values()).some((mediatype) => mediatype.isFetching)) {
                                        return true;
                                    }

                             //       if(selectedCategory.length === 0) {
                               //         return true;
                                 //   }


                                const catFetching = Array.from(selectedCategory).map((cat) => {
                                        const catObj = selectedMediatypeObj.categories.get(cat);
                                        if(!catObj) {
                                            return true;
                                        }

                                        if(catObj.isFetching) {
                                            return true;
                                        }

                                        return false;
                                    }).some(bool => bool)

                                    return catFetching;
                                })()
                            }
                        />
                        </div>
                </div>

            </Collapse>
            <Divider style={{marginTop: 30, marginBottom: 30}} />
        </div>
    )
}


function getDateRange(mediatypes : Map<MediaTypes, Mediatype>, selectedMediaType : MediaTypes | null, selectedCategory: string[], selectedCountry: Set<string>) {
    if(!selectedMediaType) {
        return {
            min: 0,
            max: 100,
        }
    }
    const mediatype = mediatypes.get(selectedMediaType)
    if(!mediatype) {
        return {
            min: 0,
            max: 100,
        }
    }
    const categories = Array.from(selectedCategory).map(id => {
        return mediatype.categories.get(id)
    })

    let min : number | undefined = undefined;
    let max : number | undefined = undefined;

    categories.forEach((cat, i) => {

        if(!cat ) {
            return;
        }



        if( cat.type === ChartTypes.BarChart){
            const filteredCharts = Array.from(cat.charts.values()).filter(chart => {
                if(selectedCountry.size === 0) {
                    return true;
                }
                return selectedCountry.has(chart.country._id);
            });

            filteredCharts.forEach((chart, i) => {
                const years = chart.xValues.map(d => new Date(d).getFullYear());
                if(!min || Math.min(...years) < min) {
                    min = Math.min(...years)
                }
                if(!max || Math.max(...years) > max) {
                    max = Math.max(...years)
                }
            })
        }

        if(cat.type === ChartTypes.PieChart){
            const filteredCharts = Array.from(cat.charts.values()).filter(chart => {
                if(selectedCountry.size === 0) {
                    return true;
                }
                return selectedCountry.has(chart.country._id);
            });

            filteredCharts.forEach((chart, i) => {
                const years = chart.pies.map((pie) => new Date(pie.label).getFullYear() );
                if(!min || Math.min(...years) < min) {
                    min = Math.min(...years)
                }
                if(!max || Math.max(...years) > max) {
                    max = Math.max(...years)
                }
            })
        }



    })

    if(!min || !max) {
        return {
            min: null,
            max: null
        }
    }
    return {
        min: min,
        max: max,
    }
}

export default Filters;
