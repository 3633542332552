let row1 = ["#0F3616","#570503","#D85208","#3C0B22","#051C65","#131925","#3A3A3A"]
let row2 = ["#286435","#87120B","#E8821A","#571435","#104194","#20293A","#747373"]
let row3 = ["#529363","#B02F20","#F2AC3C","#873363","#2C70BA","#475468"]
let row4 = ["#8CCBA0","#E99B91","#FADDA2","#D89EC2","#5DC4FA","#AFB9C8"]
let row5 = ["#C6E5D0","#F4CDC8","#FFF1BE","#ECCFE1","#AEE2FD"]


const colors = row3.concat(row4).concat(row2).concat(row1).concat(row5)
const singleColorsSmall = ["#ECCFE1","#D8B9CC","#C5A3B7","#B18EA1","#9E788C","#8A6277","#774C62","#63374C","#502137","#3C0B22"]
const singleColorsBig = ["#ECCFE1","#E5C7D9","#DDBFD1","#D6B7C9","#CFAEC1","#C7A6B9","#C09EB1","#B996A9","#B18EA1","#AA8699","#A37D91","#9B7589","#946D82","#8D657A","#855D72","#7E556A","#774C62","#6F445A","#683C52","#61344A","#592C42","#52243A","#4B1B32","#43132A","#3C0B22"]

function hex(c: any) {
    var s = "0123456789abcdef";
    var i = parseInt(c);
    if (i == 0 || isNaN(c)) return "00";
    i = Math.round(Math.min(Math.max(0, i), 255));
    return s.charAt((i - (i % 16)) / 16) + s.charAt(i % 16);
  }

  /* Convert an RGB triplet to a hex string */
  function convertToHex(rgb: any) {
    return hex(rgb[0]) + hex(rgb[1]) + hex(rgb[2]);
  }

  /* Remove '#' in color hex string */
  function trim(s: any) {
    return s.charAt(0) == "#" ? s.substring(1, 7) : s;
  }

  /* Convert a hex string to an RGB triplet */
  function convertToRGB(hex: any) {
    var color = [];
    color[0] = parseInt(trim(hex).substring(0, 2), 16);
    color[1] = parseInt(trim(hex).substring(2, 4), 16);
    color[2] = parseInt(trim(hex).substring(4, 6), 16);
    return color;
  }

  function generateColor(
    colorStart: any,
    colorEnd: any,
    colorCount: any,
    def?: any
  ) {
    if (colorCount === 2) {
      return [def ? def : colorStart];
    }
    // The beginning of your gradient
    var start = convertToRGB(colorStart);

    // The end of your gradient
    var end = convertToRGB(colorEnd);

    // The number of colors to compute
    var len = colorCount;

    //Alpha blending amount
    var alpha = 0.0;

    var saida = [];

    for (var i: any = 0; i < len; i++) {
      var c = [];
      alpha += 1.0 / len;

      c[0] = start[0] * alpha + (1 - alpha) * end[0];
      c[1] = start[1] * alpha + (1 - alpha) * end[1];
      c[2] = start[2] * alpha + (1 - alpha) * end[2];

      saida.push(convertToHex(c));
    }

    return saida;
  }




const specificColors:any = {


}


fetch("https://mediavision.se/wp-json/acf/v2/options",  {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json'
    },
}).then((res:any) => {
    return res.json()
}).then((r) => {
    r.acf.colors.map((row:any) => {
        specificColors[row.tjanst] = row.farg
    })
})

const getColors = (labels:string[], singleColor = false) => {
    if(labels.length === 1){
        return ["#873363"]
    }
    let clr = colors
    if(singleColor){
        clr=generateColor("#3C0B22","#ECCFE1", labels.length)
    }
    let index = 0;
    let usedColors:[string] = [""]
    return labels.map((label) => {
        let cleanLabel = label.toLowerCase().trim()
        if(specificColors[cleanLabel]){
            usedColors.push(specificColors[cleanLabel])
            return specificColors[cleanLabel]
        }

        while(usedColors.indexOf(clr[index]) !== -1){
            index++
        }
        usedColors.push(clr[index]);
        return clr[index]


    })


}

export default getColors
