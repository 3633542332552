import {get, post,put, deleteReq} from "./restTemplate"

export function getRoles(){
    return get(`/userrole`, "asd")
}



export function saveRole(body:any){
    return post(`/userrole`,JSON.stringify(body), "asd")
}

export function putRole(role:any){
    return put('/userrole', JSON.stringify(role), "sd")
}

export function deleteRole(id:any){
    return deleteReq(`/userrole?id=${id}`, "asd")

}
