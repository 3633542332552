import React, {useState} from "react";
import {
    deleteBarChart, deletePieChart,
    deleteWaterfallChart, getChartsForCategory,
    updateBarChartData,
    updatePieChartData
} from "../../services/chartService";
import {Button, Card, CardContent, FormControl, Input, TextField} from "@material-ui/core";
import Select from "../../components/forms/Select/Select";
import {makeStyles} from "@material-ui/core/styles";
import ConfirmationModal from "../../components/layout/ConfirmationModal";

let decimals = [{value:"0", name:"0"},{value:"1", name:"1"}, {value:"2", name:"2"},{value:"3", name:"3"},{value:"4", name:"4"}]
const xTypeList = [{value:"year", name:"Year"},{value:"quarter", name:"Quarter"},{value:"other", name:"Other"}]

const useStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
        marginBottom: 12,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    defFormControl: {
        width: '100%',
        // marginRight: 10,
        // marginBottom: 10
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

// {chart: any, isOpen, setIsOpen, deleteFile, barType, fileInput, updateChart}
export const AdminCategoryFile: React.FC<any> = ({chart, isOpen, setIsOpen, barType, fileInput, updateChart, category, setCharts}) => {
    const [editing, setEditing] = useState(false)
    const [hasDecimals, setHasDecimals] = useState(chart.hasDecimals)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [xLabel, setXLabel] = useState(chart.xLabel)
    const [yLabel, setYLabel] = useState( chart.yLabel)
    const [xType, setXType] = useState( chart.xType)
    const [yType, setYType] = useState( chart.yType)

    const toggleDeleteModal = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation)
    }

    function deleteFile() {
        let id = chart._id;
        let promise;

        switch (barType) {
            case "barGraph":
                promise = deleteBarChart(id)
                break;
            case "waterfallGraph":
                promise = deleteWaterfallChart(id)
                break;
            case "pieGraph":
                promise = deletePieChart(id)
                break;
            default:
                console.log("error")
                return;

        }
        promise.then((res) => {
            return getChartsForCategory(category._id)
        }).then((charts: any) => {
            setCharts(charts.graphs)
        })
    }


    const classes = useStyles();

    function update() {
        let obj: any = {
            data: null,
            id: chart._id,
            hasDecimals: hasDecimals,
            xLabel,
            yLabel,
            xType,
            yType,
        }
        if (chart.bars) {

            updateBarChartData(obj).then((res) => {
                console.log(res)
                setEditing(false);
            })
        } else {
            updatePieChartData(obj).then((res) => {
                setEditing(false)
            })
        }


    }

    return (
        <Card className={classes.card} style={{
            boxShadow: "none",
            border: "1px black solid",
            marginTop: "15px"
        }}>
            <CardContent>
                <div className='category-header-container'>
                    <h3 onClick={() => {
                        var map = new Map<string, boolean>(isOpen)
                        map.set(chart._id, map.get(chart._id) ? false : true)
                        setIsOpen(map);
                    }} style={{cursor: "pointer", display: "inline-block"}}>{chart.title ? chart.title : null}</h3>

                    <div className="button-block">
                        <Button variant="contained" color="secondary" style={{float: "right"}} onClick={() => {
                            // deleteFile(chart._id)
                            setShowDeleteConfirmation(true)
                        }}>Delete</Button>
                        <Button variant="contained" style={{float: "right"}} onClick={() => {
                            editing ? update() : setEditing(true)
                        }}>{editing ? "Update" : "Edit"}</Button>
                    </div>
                </div>
                <p><b>Land: </b> {chart.country.title}</p>
                <p><b>Medietyp: </b> {chart.mediaType}</p>
                {
                    editing ? (
                        <>
                            <div className="col-md-6">
                                <TextField value={hasDecimals} fullWidth label={"Has decimals"}
                                           onChange={(e) => setHasDecimals(e.target.value)}/>
                            </div>

                            <div className="col-md-6">
                                <TextField value={xLabel} fullWidth label={"X label"}
                                           onChange={(e) => {
                                               setXLabel(e.target.value)
                                           } }/>
                            </div>

                            <div className="col-md-6">
                                <TextField value={yLabel} fullWidth label={"Y label"}
                                           onChange={(e) => setYLabel(e.target.value) }/>
                            </div>


                            <div className="col-md-6">
                                <FormControl className={classes.defFormControl}>
                                    <Select options={xTypeList} label={"x type"} value={xType}
                                            onChange={(value) => {
                                                setXType(value.target.value)
                                            }}/>
                                </FormControl>

                                {/*<TextField value={xType} fullWidth label={"X Type"}*/}
                                {/*           onChange={(e) => setXType(e.target.value) }/>*/}
                            </div>

                            <div className="col-md-6">
                                <FormControl className={classes.defFormControl}>
                                    <Select options={[{
                                        value: "percentage",
                                        name: "Percentage"
                                    }, {value: "other", name: "Other"}]} label={"y type"} value={yType}
                                            onChange={(value) => {
                                                setYType(value.target.value)
                                            }}/>
                                </FormControl>
                            </div>
                        </>

                    ) : (<p><b>Has decimals </b> {hasDecimals}</p>)
                }


                {
                    isOpen.get(chart._id) ? (
                        <div>


                            {
                                barType === "barGraph" ? (
                                    <div>
                                        <p><b>X-label: </b>{chart.xLabel}</p>
                                        <p><b>Y-label: </b>{chart.yLabel}</p>
                                        <p><b>X-type: </b> {chart.xType}</p>
                                        <p><b>Y-type: </b>{chart.isPercentage ? "Percentage" : "Other"}</p>

                                    </div>
                                ) : null
                            }

                            {
                                barType === "pieGraph" ? (
                                    <div>
                                        <p><b>Category label: </b>{chart.categoryLabel}</p>
                                    </div>
                                ) : null
                            }

                            <h3>Update file</h3>
                            <div style={{display: chart.uploaded ? "none" : "block"}}>
                                <Input type={"file"} ref={fileInput} onChange={(e) => {
                                    const target = e.target as HTMLInputElement;
                                    const file: File = (target.files as FileList)[0];
                                    chart.updatedFile = file
                                    chart.target = target
                                }}/>

                                <Button variant="contained" color="primary" onClick={() => {
                                    updateChart(chart)
                                }}>Update file</Button>
                            </div>
                            <div style={{display: chart.uploaded ? "block" : "none"}}>
                                Updated!
                            </div>
                        </div>
                    ) : null
                }

            </CardContent>

            <ConfirmationModal show={showDeleteConfirmation} onConfirmation={deleteFile} toggleFunc={toggleDeleteModal} />
        </Card>
    )
}
