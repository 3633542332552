import {AdminCategoryFile} from "./AdminCategoryFile";
import {Button, CardContent} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {AdminCategoryModal} from "./AdminCategoryModal";
import { useStyles} from "./Styles";
import {getCountries} from "../../services/countryService";

interface Props {
    usedCharts: any,
    setIsOpen: any,
    isOpen: any,
    // barType: any,
    category: any,
    fileInput: any,
    updateChart: any,
    setCharts: any,
    // newTitle: any,
    // setNewTitle: any
}


export const AdminCategoryAssociatedFiles: React.FC<Props> = (
    {
        usedCharts, setIsOpen, isOpen,
        category, fileInput, updateChart,
        setCharts,
        // newTitle,
        // setNewTitle
    }
) => {
    const [addFileOpen, setAddFileOpen] = useState(false);
    const classes = useStyles();
    const [countries, setCountries] = useState([])
    const barType = category.barType

    useEffect(() => {
        getCountries().then((res) => {
            setCountries(res.map((cat: any) => {
                return {value: cat._id, name: cat.title}
            }))
        });
    }, [])




    return <>

        <h3 style={{marginTop: 50}}>Files associated to this category</h3>
        {
            usedCharts.map((chart: any) => {
                return (
                    <AdminCategoryFile chart={chart} isOpen={isOpen} setIsOpen={setIsOpen}
                                       setCharts={setCharts}
                                       barType={barType} fileInput={fileInput}
                                       category={category}
                                       updateChart={updateChart}/>
                )

            })

        }
        {
            usedCharts.length === 0 ? (<p>There are no files associated with this category</p>) : null
        }
        <Button variant="contained" color="primary" style={{marginTop: 20}} onClick={() => {
            setAddFileOpen(true)
        }}>
            Add new file
        </Button>


        <AdminCategoryModal
            addFileOpen={addFileOpen}
            setAddFileOpen={setAddFileOpen}
            classes={classes}
            countries={countries}
            barType={barType}
            category={category}
            setCharts={setCharts}
        />
    </>
}
