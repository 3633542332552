import React, {useEffect, useState} from "react";
import {getModalStyle, useStyles} from "./Styles";
import {
    getChartsForCategory,
    updateBarChartData
} from "../../services/chartService";
import {deleteCategory, updateCategory} from "../../services/categoryService";
import {Button, Card, CardContent, Container, FormControl, Grid, InputLabel, TextField} from "@material-ui/core";
import ConfirmationModal from "../../components/layout/ConfirmationModal";
import Select from "../../components/forms/Select/Select";
import {Alert} from "@material-ui/lab";
import {AdminCategoryAssociatedFiles} from "./AdminCategoryAssociatedFiles";


export const Category: React.FC<any> = (props) => {
    const [editing, setEditing] = useState(false)
    const [text, setText] = useState(props.category.title)
    const [graphType, setGraphType] = useState(props.category.barType)
    const classes = useStyles();
    const [charts, setCharts] = useState([])
    const [isOpen, setIsOpen] = useState<Map<string, boolean>>(new Map())
    let fileInput: any = React.createRef();
    const [footnote, setFootnote] = useState(props.category.footnote)
    const [error, setError] = useState('')
    const [showCombined, setShowCombined] = useState(props.category.showCombined ? "yes" : "no")
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [showCombinedAlternative, setShowCombinedAlternative] = useState(props.category.showCombinedAlternative ? "yes" : "no")
    let asd = "no"
    if (props.category.absRel) {
        asd = props.category.individual ? "individual" : "household"
    }
    const [absRel, setAbsRel] = useState(asd)


    const [headCategory, setHeadCategory] = useState(props.category.headCategory ? props.category.headCategory._id : null)
    const [order, setOrder] = useState(props.category.order)
    const [subtitle, setSubtitle] = useState(props.category.subtitle)

    useEffect(() => {
        getChartsForCategory(props.category._id).then((charts: any) => {
            setCharts(charts.graphs)
        })
    }, [])

    const toggleDeleteModal = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation)
    }

    const updateCategoryRoutine = () => {
        let update = JSON.parse(JSON.stringify(props.category))
        update.title = text
        update.barType = graphType
        update.footnote = footnote
        update.headCategory = headCategory
        update.order = order
        update.subtitle = subtitle
        update.showCombined = showCombined === "yes"
        update.showCombinedAlternative = showCombinedAlternative === "yes"
        update.absRel = absRel === "individual" || absRel === "household"
        update.individual = absRel === "individual"

        updateCategory(update).then(async (res) => {
            const status = res.status;
            const response = await res.json();

            if (response && response.details && response.details[0]) {
                setError(response.details[0].message)
            }
            props.updateCategories()
            if (status === 200) {
                setError('')
                setEditing(false)
            }
        })
    }

    function getBase64(file: any, cb: any) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }


    function updateChart(chart: any) {
        getBase64(chart.updatedFile, (a: any) => {

            let obj: any = {
                title: chart.updatedFile.name,
                data: a.split(",")[1],
                id: chart._id
            }

            updateBarChartData(obj).then((res) => {
                chart.target.value = null

                return getChartsForCategory(props.category._id)
            }).then((charts: any) => {
                setCharts(charts.graphs)
            })
        })
    }



    function deleteCat() {
        deleteCategory(props.category._id).then((res) => {
            props.updateCategories()
        })
    }

    let usedCharts: any = []

    if (props.searchText !== "") {
        usedCharts = charts.filter((chart: any) => chart.title.startsWith(props.searchText))
        if (usedCharts.length === 0) {
            return <div></div>
        }
    } else {
        usedCharts = charts
    }

    return (
        <div className='admin-category'>
            <Card className={classes.card} style={{
                boxShadow: "none",
                border: "1px black solid",
                marginTop: "20px"
            }}>
                <CardContent>
                    {
                        editing ?
                            (<div className="category-form">
                                <div className="row">
                                    {error ?
                                        <Alert className="mb-3" severity="error">{error}</Alert> : '' }
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <TextField value={text} placeholder={"Title"} fullWidth label={'Title'}
                                                   onChange={(e) => setText(e.target.value)}/>
                                    </div>

                                    <div className="col-md-6">
                                        <TextField value={order} placeholder={"Order"} fullWidth className="mb-md-3" label={"Order"}
                                                   onChange={(e) => setOrder(e.target.value)}/>
                                    </div>
                                    <div className="col-md-6" >
                                        <TextField value={subtitle} placeholder={"Subtitle"} fullWidth label={"Subtitle"}
                                                   onChange={(e) => setSubtitle(e.target.value)}/>
                                    </div>

                                    <div className="col-md-6">
                                        <TextField value={footnote} fullWidth label={"Footnote"}
                                                   onChange={(e) => setFootnote(e.target.value)}/>
                                    </div>

                                    <div className="col-md-6">
                                        <FormControl fullWidth  >
                                            <Select options={[{value: "yes", name: "Yes"}, {value: "no", name: "No"}]}
                                                    label={"Show combined"} value={showCombined} onChange={(value) => {
                                                setShowCombined(value.target.value)
                                            }}/>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6">
                                        <FormControl fullWidth={true}>
                                            <Select  options={[{value: "yes", name: "Yes"}, {value: "no", name: "No"}]}
                                                    label={"Show combined alternative"} value={showCombinedAlternative}
                                                    onChange={(value) => {
                                                        setShowCombinedAlternative(value.target.value)
                                                    }}/>
                                        </FormControl>
                                    </div>
                                    <div className="col-md-6">
                                        <FormControl fullWidth={true} >
                                            <Select options={[{value: "individual", name: "Individual"}, {
                                                value: "household",
                                                name: "Household"
                                            }, {value: "no", name: "No"}]} label={"Abs/Rel"} value={absRel}
                                                    onChange={(value) => {
                                                        setAbsRel(value.target.value)
                                                    }}/>
                                        </FormControl>
                                    </div>

                                    <div className="col-md-6">
                                        <FormControl fullWidth={true} className={classes.defFormControl}>
                                            <Select options={props.headCategories} label={"Huvudkategori"}
                                                    defaultValue={""}
                                                    value={headCategory} onChange={(value) => {
                                                setHeadCategory(value.target.value)
                                            }}/>
                                        </FormControl>
                                    </div>
                                </div>
                                <div className="row" style={{marginTop: 10}}>
                                    <div className="button-block">
                                        <Button variant="contained" color="secondary"
                                                onClick={() => {
                                                    setEditing(false)
                                                }}>
                                            Cancel
                                        </Button>
                                        <Button variant="contained" color="primary" onClick={() => {
                                            updateCategoryRoutine()
                                        }}>
                                            Update
                                        </Button>
                                    </div>
                                </div>
                            </div>)
                            :
                            (<div>
                                <div className='category-header-container'>
                                    <h2 style={{display: "inline-block", wordBreak: "break-all"}}>{props.category.title}</h2>
                                    <div className="button-block">
                                        <Button style={{marginLeft: 10}} variant="contained" color="primary"
                                                onClick={() => setEditing(true)}>Edit</Button>

                                        <Button color="secondary" variant="contained" style={{float: "right"}}
                                                onClick={() => {
                                                    setShowDeleteConfirmation(true)
                                                }}>
                                            Delete
                                        </Button>
                                    </div>
                                </div>

                                <p style={{marginTop: 0}}><b>Typ av kategori:</b> {props.category.barType}</p>
                                <p style={{marginTop: 0}}><b>Fotnot:</b> {props.category.footnote}</p>
                                <p style={{marginTop: 0}}><b>Subtitle:</b> {props.category.subtitle}</p>

                            </div>)
                    }

                    {/* <hr /> */}
                    <AdminCategoryAssociatedFiles
                        usedCharts={usedCharts}
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                        category={props.category}
                        fileInput={fileInput}
                        updateChart={updateChart}
                        setCharts={setCharts}
                        // newTitle={newTitle}
                        // setNewTitle={setNewTitle}


                        // usedCharts={usedCharts}
                        //                           setIsOpen={setIsOpen}
                        //                           isOpen={isOpen}
                        //                           barType={props.category.barType}
                        //                           category={props.category}
                        //                           fileInput={fileInput}
                        //                           updateChart={updateChart}
                        //                           setCharts={setCharts}
                        //                           setAddFileOpen={setAddFileOpen}
                    />



                </CardContent>
            </Card>


            <ConfirmationModal show={showDeleteConfirmation} onConfirmation={deleteCat} toggleFunc={toggleDeleteModal} />
        </div>
    )
}
