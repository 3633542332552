import React, { useEffect, useState, useMemo  } from 'react';
import { Container, TextField, Button, Input, Card, CardContent} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { useAuth0} from "../../react-auth0-spa";
import { Link } from 'react-router-dom';
import {getRoles} from './../../services/roleService'
import {resetPass} from '../../services/userService'

import _ from 'lodash';
const useStyles = makeStyles({
  card: {
    minWidth: 275,
     marginBottom: 12,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


interface Role {
  countrySet: string,
  premiumUpdated: string,
  title: string,
  updated: string
}
const AdminBulk : React.FC = () => {
    const classes = useStyles();
    let {user} = useAuth0()
    console.log(user)
    const [firstImage, setFirstImage] = useState<string>("");
    const [secondImage, setSecondImage] = useState<string>("");
    const [ contactTitle, setContactTitle] = useState<string>("");
    const [ contactText, setContactText] = useState<string>("");
    const [ bottomText, setBottomText] = useState<string>("");

    const [roles, setRoles] = useState<any[]>([]);
    const [ reset, setRest] = useState<boolean>(false);
    const [ showLast, setShowLast] = useState<boolean>(false);

    const groupedRoles = useMemo(() => {
      return _.chain(roles).groupBy("countrySet").toArray().value()
    }, [roles])
    useEffect(() => {
      fetch("https://mediavision.se/wp-json/acf/v2/options",  {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json'
        },
      }).then((res:any) => {
        return res.json();
      }).then((a:any) => {

        setContactTitle(a.acf.contact_title);
        setContactText(a.acf.contact_text);

        setBottomText(a.acf.bottom_text_my_account)

        setFirstImage(a.acf.portal_images)
        setSecondImage(a.acf.second_image)

      })
    }, [])

    useEffect(() => {
      getRoles().then((res) => {

        setRoles(res.filter((role:any) => role.visible ));
      });
    }, [])

    let resetPassword = () => {
      resetPass()
      setRest(true)
    }

    let showLastFunc = () => {
      setShowLast(true)
    }




    return (
      <section className="frontpage">

        <div className="frontpage_welcome">

          <div className="page_header"  style={{backgroundImage: "url(" + firstImage + ")"	}}>
            <div className="container">
              <h1 className="page_title">My account</h1>
            </div>
          </div>



          <div className="text-column">
          <main className="page-me">
            <div className="userinfo">
              <div className="title-container">
                <h2 className="userinfo__name">{user.name}</h2>
                <p className="userinfo__company">
                Mediavision
                {
                  // TODO: replace with actual companyu
                }
              </p>
              </div>
              {
              showLast ? (
                <>
                {
                  reset ? (<p>Reset instructions will be sent to your email.</p>) : (
                    <>
                      <p>Are you sure?</p>
                      <Button onClick={resetPassword}>Yes</Button>
                    </>
                  )
                }

                </>
              ) : (
                <Button onClick={showLastFunc}>Reset password</Button>
              )
            }
            </div>

            <div className="bundle">
              <div className="row titles">
                <p className="left">Bundle</p>
                <p className="right">Last updated</p>
              </div>
              {
                roles.map((role) => {
                  let usrRole = user["https://mediavision.se/roles"].filter((x:any) => {
                    return x.name === role.title;
                  })[0]
                  if(!usrRole){
                    return;
                  }
                  return (
                  <div className="row">
                    <p className="left">{usrRole.description}</p>
                    <p className="right">{role.updated}</p>
                  </div>
                )
              })
              }
            </div>

          {/* <div>
            <h2>Byt lösenord</h2>
            <p>Nytt lösenord:</p><input type="password" />
            <p>Bekräfta lösenord</p>  <input type="password" />
            <br />
            <br />
            <Button variant="outlined" onClick={() => {

            }}>Byt lösenord</Button>
          </div> */}
          </main>
          </div>




          <section className="section-link-with-background">
              <div className="container">

                  <div className="content">
                      <div className="content-text">
                          <h2 className="link-with-background_title">
                            {contactTitle}
                          </h2>

                          <div className="link-with-background_text">
                            {contactText}
                          </div>

                          <a className="button" role="button" href="https://mediavision.se/en/home/">Contact us</a>
                      </div>
                  </div>
              </div>
          </section>

        </div>

    </section>

    )
}

export default AdminBulk;
