import React from 'react';
import { FormControl, InputLabel, Input, Select, MenuItem, ListSubheader } from '@material-ui/core';
import { makeStyles, useTheme, DefaultTheme } from '@material-ui/styles';
import clsx from 'clsx';

interface SelectProps {
    options: Array<{
        value: string
        name: string
    }>
    label: string
    defaultValue?: string,
    value: string | undefined,
    onChange(e: any) : void
    disabled?: boolean,
    className?: string
}



const useStyles = makeStyles(theme => ({
    formControl: {
        marginBottom: 10,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
  }));

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };



const CustomSelect : React.FC<SelectProps> = ({options, label, defaultValue, value, onChange, disabled, className}) => {
    const classes = useStyles();

    return (
        <FormControl className={clsx(classes.formControl, className)}>
            <InputLabel>
                {label}
            </InputLabel>
            <Select disabled={disabled || options.length === 0} onChange={onChange} defaultValue={defaultValue} value={value} input={<Input />}>
                {
                    options.map(option => (
                        <MenuItem key={option.value} value={option.value}>{option.name}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>
    )
}

export default CustomSelect;
