import React from "react";
import headerImage from '../../Assets/images/header-optimized.png'

export const HeaderBanner = ({welcomeText}) => {

    return   <div className="page_header"  style={{backgroundImage: "url("+ headerImage + ")"	}}>
        <div className="container">
            <h1 className="page_title">{welcomeText}</h1>
        </div>
    </div>
}
