import React, {useEffect, useState} from 'react';
import downloadImage from "../../Assets/images/download.svg";
import settingsImage from "../../Assets/images/settings.svg";
import moment from "moment";
import {postReportData} from "../../services/reportService";

interface ReportObj {
    report_name: string,
    report_group: string,
    publishing_date: string,
    file: string,
    file_path: string,
}


export const getReportRows = (reports: any[], setCurrentReportFunc: any, filter: string, isUserAnAdmin: boolean,  userName: string, user: any, setNotificationSent: any)  => {
    const rows = reports.map((item: ReportObj, key) => {
        const containsChar = item.report_name.includes(filter) || item.report_group.includes(filter)
        if (!containsChar) {
            return <tr></tr>
        }

        const downloadReport = async () => {
            const body = JSON.stringify({
                name: userName,
                reportObjName: item.report_name,
                reportName: item.file_path,
                userId: user,
                report: item
            });
            await postReportData(body).then(res => {
                setNotificationSent(res.status)
            });
        }

        return  <tr key={key}>

                <td>{item.report_name}</td>
                <td>{item.report_group}</td>
                <td>{moment(item.publishing_date).format('YYYY-MM-DD')}</td>
                <td>
                    <a onClick={downloadReport} href="#"  >
                        <img src={downloadImage} alt="download" />
                    </a>

                    {isUserAnAdmin ?
                        <a href="#" onClick={() => setCurrentReportFunc(item)}>
                            <img src={settingsImage} alt="settings" className="mx-1" />
                        </a> : ''}
                </td>
            </tr>
    })
    return rows;
}
