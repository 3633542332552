import {makeStyles} from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    card: {
        minWidth: 275,
        marginBottom: 12,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    defFormControl: {
        // width: 450,
        width: '100%',
        marginRight: 10,
        marginBottom: 10,
    },
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    widePopUpBlock: {
        position: 'absolute',
        width: "100%",
        maxWidth: 1200,
        backgroundColor: theme.palette.background.paper,
    },
}));


export function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
