import moment from "moment";
import settingsImage from "../../Assets/images/settings.svg";
import React from "react";
import {ReportGroupInterface} from "./interfaces";

export const ReportGroupRows = (reports, setCurrentReportFunc, filter, isUserAnAdmin, setOverlay, displayName) => {

    return reports.map((item: ReportGroupInterface, key) => {
        const containsChar = item.name.includes(filter)
        if (!containsChar) {
            return <tr></tr>
        }

        return <tr key={key}>
            <td>{item.name}</td>
            <td>{moment(item.publishing_date).format('YYYY-MM-DD')}</td>
            <td>
                {isUserAnAdmin ?
                    <a href="#" onClick={() => setCurrentReportFunc(item)}>
                        <img src={settingsImage} alt="settings" className="mx-1" />
                    </a> : ''}
            </td>
        </tr>
    });


}
