import {get, post, put, deleteReq, deleteMethod} from "./restTemplate"
import {urlService} from "./config"

export function getMediaTypes(){
    return get(`/mediatypes`, "asd")
}

export function getCategories(){
    return get(`/categories`, "asd")
}

export function createCategory(category:string, graphType:string, footnote:string, headCateogry:string){
    return post(`/categories`,JSON.stringify({title:category, barType:graphType, footnote:footnote, headCategory:headCateogry, showCombined:false}), "asd")
}


export function updateCategory(category:any){
    return put(`/categories`,JSON.stringify(category), "asd")
}


export function deleteCategory(id:any){
    return deleteReq(`/categories?id=${id}`, "asd")
}


export function getHeadCategories(){
    return get("/headcategories","asd");
}

export function createHeadCategory(title:string, topHeadCategory:string){
    return post(`/headcategories`,JSON.stringify({title:title, topHeadCategory}), "asd")
}

export function getTopHeadCategories(){
    return get("/topheadcategories","asd");
}

export function createTopHeadCategory(title:string){
    return post(`/topheadcategories`,JSON.stringify({title:title}), "asd")
}

export function createReports(report:any){
    return post(`/reports`, report, "asd", true)
}

export function updateReports(report:any){
    return put(`/reports`, report, "asd", true)
}

export function createReportGroup(reportGroup:any){
    return post(`/report-groups`, reportGroup, "asd", true)
}

export function updateReportGroup(report:any){
    return put(`/report-groups`, report, "asd", true)
}

export function deleteReportGroup(report:any){
    return deleteMethod(`/report-groups`,JSON.stringify(report), "asd")
}

export function uploadReportsAccessFile(report:any){
    return post(`/report-permissions`, report, "asd", true)
}

export function deleteReports(report:any){
    return deleteMethod(`/reports`,JSON.stringify(report), "asd")
}

export function bulkUpdate(obj:any){
    return post(`/admin/updateBulk`,JSON.stringify(obj), "asd")
}

export function updateTopHeadCategory(topHead:any){
    return put(`/topheadcategories`,JSON.stringify(topHead), "asd")
}

export function deleteTopHeadCategory(topHead:any){
    return deleteReq(`/topheadcategories?id=${topHead}`, "asd")
}

export function updateHeadCategory(head:any){
    return put(`/headcategories`,JSON.stringify(head), "asd")
}

export function deleteHeadCategory(topHead:any){
    return deleteReq(`/headcategories?id=${topHead}`, "asd")
}

export async function bulkDownload(obj:any){
    let tokens = localStorage.getItem('token')
    let method= "/admin/downloadBulk"
    const pageRes = await fetch(urlService + method, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokens}`

        },
        body: JSON.stringify(obj)
    });
    try {

        return await pageRes.clone().blob()
    } catch(err) {
       return await pageRes.text()
    }}
