import React, {useState} from "react";
import {ReportTableSimplified} from "./ReportTableSimplified";
import {resetPass} from "../../services/userService";
import search from '../../Assets/images/search.svg';
import {makeStyles} from "@material-ui/core/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import {Button} from "@material-ui/core";


export const DisplayReportBlock = ({setIsOpen, setIsOpenKPIFailed}) => {
    const [filter, setFilter] = useState('');
    const [pwReseted, setPwReseted] = useState(false);
    const matches = useMediaQuery('(min-width:600px)');
    const doSearch = (e) => {
        setFilter(e.target.value)
    }
    const classes = styles();

    const resetPassword = () => {
        setPwReseted(true)
        resetPass()
    }

    return <div className={classes.container}>
        <p className={classes.title}>Your products</p>
        <div className={classes.eachEnd}>
            {matches ?
            <div className={classes.inputBlock}>
                <input placeholder="Search..." type="input" className={classes.search} value={filter} onChange={doSearch}/>
                <img src={search} alt="search-icon"/>
            </div> :
                <input placeholder="Search..." type="input" className={classes.search} value={filter} onChange={doSearch}/>
            }

            {pwReseted ? "Reset instructions will be sent to your email." :
                <Button  color="primary" className={classes.resetPW}  onClick={() => resetPassword()}>
                    Reset password
                </Button>
            }
        </div>

        <ReportTableSimplified filter={filter} setIsOpen={setIsOpen} setIsOpenKPIFailed={setIsOpenKPIFailed}/>

    </div>
}


export const styles = makeStyles(theme => ({
    title: {
        marginTop: "30px",
        // fontFamily: 'Lato',
        fontWeight: 700,
        fontStyle: "normal",
        color: "#4C4C4C",
        fontSize: 24,
    },
    inputBlock: {
        backgroundColor: theme.palette.info.light,
        width: "300px",
        borderRadius: "44px",
        border: "none",
    },
    search: {
        width: "265px",
        borderRadius: "44px",
        border: "none",
        backgroundColor: theme.palette.info.light,
        height: "35px",
        paddingLeft: "15px",
        paddingBottom: "5px",
        outline: "none",
    },
    section: {
        fontSize: "1rem",
        fontWeight: 1.5,
        lineHeight: 1.5,
        color: "#292b2c",
        backgroundColor: "#fff",
        padding: "0 2em",
    },
    eachEnd: {
        display: "flex",
        justifyContent: "space-between"
    },
    container: {
        // display: "flex",
        padding: "20px",
        width: "100%",
        maxWidth: "1300px",
        minHeight: "700px",
    },
    resetPW: {
        // backgroundColor: theme.palette.info.light,
        // padding: "0 20px 0 20px",
        // borderRadius: "37px",
        // boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
        // fontSize: "13px",
        // fontWeight: 700,
        // letterSpacing: "0.2em",
        // height: "35px",
        textTransform: "initial",
        color: theme.palette.info.dark,
        backgroundColor: theme.palette.info.main,
    }
}));
