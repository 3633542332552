import React from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {Button} from "@material-ui/core";

export interface GroupedSelectProps {
    show: boolean
    onConfirmation() : void
    toggleFunc() : void
}

const ConfirmationModal : React.FC<GroupedSelectProps> = ({show, onConfirmation, toggleFunc}) => {


    return (
        <>
            <Modal isOpen={show} toggle={() => toggleFunc()}>
                <ModalHeader >
                    Delete confirmation
                </ModalHeader>
                <ModalBody>
                    Are you sure, you want to delete this?
                </ModalBody>
                <ModalFooter>
                    <div className="button-block">
                        <Button onClick={toggleFunc} variant="contained" >
                            Cancel
                        </Button>

                        <Button
                            onClick={() => onConfirmation() }
                            variant="contained" color="secondary"
                        >
                            Delete
                        </Button>
                    </div>

                </ModalFooter>
            </Modal>
        </>
    )
}

export default ConfirmationModal;
