import React, {useState} from "react";
import {deleteRole} from "../../services/roleService";
import {Button, Card, CardContent, FormControl, TextField} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import {RoleCategoryList} from "./RoleCategoryList";
import Modal from "@material-ui/core/Modal";
import Select from "../../components/forms/Select/Select";
import ConfirmationModal from "../../components/layout/ConfirmationModal";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    card: {
        minWidth: 275,
        marginBottom: 12,
    },
    bullet: {
        display: "inline-block",
        margin: "0 2px",
        transform: "scale(0.8)",
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },

    root: {
        width: "100%",
        maxWidth: 360,
    },
    nested: {
        paddingLeft: 30,
    },
    defFormControl: {
        // width: 450,
        width: '100%',
        marginRight: 10,
        marginBottom: 10,
    },
    paper: {
        position: "absolute",
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        display: "block",
    },
}));

function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

export const Role: React.FC<any> = (props) => {
    const [editing, setEditing] = useState(false);

    const [newCategory, setNewCategory] = useState("");
    const [addNewCategory, setAddNewCategory] = useState(false);

    const [title, setTitle] = useState(props.role.title);
    const [updated, setUpdated] = useState(props.role.updated);
    const [premiumUpdated, setPremiumUpdated] = useState(props.role.premiumUpdated);
    const [country, setCountry] = useState(props.role.countrySet);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);

    function saveNewCategory() {
        if (newCategory === "") {
            return;
        }
        props.role.categories.push({
            category: newCategory,
            countries: [],
        });
        props.updateRole(props.role);
        setAddNewCategory(false);
    }

    function removeRole() {
        deleteRole(props.role._id).then((res) => {
            props.reloadRoles();
        });
    }

    const toggleDeleteModal = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation)
    }


    return (
        <Card
            className={classes.card}
            style={{ boxShadow: "none", border: "1px black solid" }}
        >
            <CardContent>
                {
                    <div>
                        <div>
                            {editing ? (
                                <TextField
                                    value={title}
                                    onChange={(e) => {
                                        setTitle(e.target.value);
                                    }}
                                />
                            ) : (
                                <h3 style={{ display: "inline-block" }}>{title}</h3>
                            )}
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ float: "right" }}
                                onClick={() => {
                                    setShowDeleteConfirmation(true)

                                    // removeRole();
                                }}
                            >
                                Delete
                            </Button>
                        </div>
                        {editing ? (
                            <TextField
                                value={updated}
                                placeholder="Updated"
                                onChange={(e) => {
                                    setUpdated(e.target.value);
                                }}
                            />
                        ) : (
                            <p style={{ display: "inline-block" }}>Updated: {updated}</p>
                        )}
                        <br />
                        {editing ? (
                            <TextField
                                placeholder="Premium updated"
                                value={premiumUpdated}
                                onChange={(e) => {
                                    setPremiumUpdated(e.target.value);
                                }}
                            />
                        ) : (
                            <p style={{ display: "inline-block" }}>
                                premium updated: {premiumUpdated}
                            </p>
                        )}
                        <br />
                        {editing ? (<>
                            <b>Visa i roller-dashboard</b>
                            <Checkbox
                                checked={props.role.visible}
                                onChange={(value) => {
                                    props.role.visible = props.role.visible ? false : true
                                    props.updateRole(props.role);
                                }}
                            />
                        </>) : (
                            <div>
                                <b>Visa i roller-dashboard</b>
                                <p>{props.role.visible ? "Visas" : "Visas inte"}</p>
                            </div>)
                        }
                        <br />
                        {editing ? (
                            <TextField
                                placeholder="Country"
                                value={country}
                                onChange={(e) => {
                                    setCountry(e.target.value);
                                }}
                            />
                        ) : (
                            <p style={{ display: "inline-block" }}>
                                Country: {country}
                            </p>
                        )}

                        <RoleCategoryList roleCategories={props.role.categories}
                                          updateRole={props.updateRole}
                                          role={props.role}
                                          classes={classes}
                                          editing={editing}
                                          categories={props.categories}
                                          countries={props.countries}
                                          modalStyle={modalStyle}
                        />
                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={addNewCategory}
                            onClose={() => {
                                setAddNewCategory(false);
                            }}
                        >
                            <div style={modalStyle} className={classes.paper}>
                                <div style={{ marginBottom: 10 }}>
                                    <FormControl fullWidth={true} className={classes.defFormControl}>
                                        <Select
                                            options={props.categories}
                                            label={"New category"}
                                            defaultValue={""}
                                            value={newCategory}
                                            onChange={(value) => {
                                                setNewCategory(value.target.value);
                                            }}
                                        />
                                    </FormControl>
                                </div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        saveNewCategory();
                                    }}
                                >
                                    Add category
                                </Button>
                            </div>
                        </Modal>

                        <div style={{ marginBottom: 10 }}>
                            {editing ? (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        setAddNewCategory(true);
                                    }}
                                >
                                    Add new category
                                </Button>
                            ) : null}
                        </div>
                        {!editing ? (
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => setEditing(true)}
                                >
                                    Edit
                                </Button>
                            </div>
                        ) : (
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() =>
                                        props.save(title, updated, premiumUpdated,country).then(() => {
                                            setEditing(false);
                                        })
                                    }
                                >
                                    Save
                                </Button>{" "}
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => setEditing(false)}
                                >
                                    Cancel
                                </Button>{" "}
                            </div>
                        )}
                        <ConfirmationModal show={showDeleteConfirmation} onConfirmation={removeRole} toggleFunc={toggleDeleteModal}/>
                    </div>
                }
            </CardContent>
        </Card>
    );
};
