import List from "@material-ui/core/List";
import ListSubheader from "@material-ui/core/ListSubheader";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {Button, TextField} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Checkbox from "@material-ui/core/Checkbox";
import Modal from "@material-ui/core/Modal";
import Select from "../../components/forms/Select/Select";
import ConfirmationModal from "../../components/layout/ConfirmationModal";
import React, {useState} from "react";


export const RoleCategoryList: React.FC<any> = ({roleCategories, updateRole, role, classes, editing, categories, countries, modalStyle}) => {
    const [newCountry, setNewCountry] = useState("");
    const [removeCountryIds, setRemoveCountryIds] = useState({first: null, second: null});
    const [removeCategoryId, setRemoveCategoryId] = useState(null);
    const [addNewCountry, setAddNewCountry] = useState(false);
    const [showCategoryDeleteConfirmation, setShowCategoryDeleteConfirmation] = useState(false)
    const [showCountryDeleteConfirmation, setShowCountryDeleteConfirmation] = useState(false)

    const toggleCategoryDeleteModal = () => {
        setRemoveCategoryId(null)
        setShowCategoryDeleteConfirmation(!showCategoryDeleteConfirmation)
    }

    const toggleCountryDeleteModal = () => {
        setShowCountryDeleteConfirmation(!showCountryDeleteConfirmation)
    }

    function removeCategory() {
        const i = removeCategoryId
        roleCategories.splice(i, 1);
        updateRole(role);
        toggleCategoryDeleteModal()
    }

    function removeCountry() {
        const data: any = removeCountryIds;

        roleCategories[data.first].countries.splice(data.second, 1);
        updateRole(role);
        toggleCountryDeleteModal()
    }


    function saveNewCountry(i: any) {
        if (newCountry === "") {
            return;
        }

        roleCategories[i].countries.push({
            country: newCountry,
            video: false,
            text: false,
            sound: false,
            overview: false,
            access: false,
        });
        updateRole(role);
        setAddNewCountry(false);
    }

    function updateCategoryExp(i: any) {
        if (!roleCategories[i].exp) {
            roleCategories[i].exp = true;
        } else {
            roleCategories[i].exp = false;
        }

        updateRole(role);
    }

    const categoryMap = new Map<string, object>();
    const countryMap = new Map<string, object>();

    categories.map((cat: any) => {
        categoryMap.set(cat.value, cat.name);
    });

    countries.map((cat: any) => {
        countryMap.set(cat.value, cat.name);
    });


    // @ts-ignore
    return <>
        <List
            style={{ width: "100%", maxWidth: "none", marginBottom: 50 }}
            aria-labelledby="nested-list-subheader"
            subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                    Categories
                </ListSubheader>
            }
            className={classes.root}
        >
            {roleCategories.map((category: any, i: any) => {
                return (
                    <div>
                        <div>
                            <ListItem
                                button
                                onClick={() => {
                                    updateCategoryExp(i);
                                }}
                                style={{ width: "80%", display: "inline-block" }}
                            >
                                <ListItemText
                                    style={{ display: "inherit" }}
                                    primary={categoryMap.get(category.category)}
                                />

                                {category.exp ? (
                                    <ExpandLess style={{ float: "right" }} />
                                ) : (
                                    <ExpandMore style={{ float: "right" }} />
                                )}
                            </ListItem>
                            {editing ? (
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    style={{ float: "right", width: "20%" }}
                                    onClick={() => {
                                        setRemoveCategoryId(i)
                                        setShowCategoryDeleteConfirmation(true)
                                        // removeCategory(i)
                                    }}
                                >
                                    Remove category
                                </Button>
                            ) : null}
                        </div>

                        <Collapse in={category.exp} timeout="auto" unmountOnExit>
                            <List component="div">
                                {editing ? (
                                    <div>
                                        <TextField
                                            style={{
                                                display: "block",
                                                marginLeft: 30,
                                                width: "100%",
                                            }}
                                            placeholder="From year"
                                            value={category.fromYear}
                                            onChange={(e) => {
                                                category.fromYear = e.target.value;
                                                updateRole(role);
                                            }}
                                        />
                                        <TextField
                                            style={{
                                                display: "block",
                                                marginLeft: 30,
                                                width: "100%",
                                            }}
                                            placeholder="To year"
                                            value={category.toYear}
                                            onChange={(e) => {
                                                category.toYear = e.target.value;
                                                updateRole(role);
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div style={{ marginLeft: 30 }}>
                                        <b>From</b>
                                        <p>{category.fromYear}</p>
                                        <b>To</b>
                                        <p>{category.toYear}</p>
                                    </div>
                                )}

                                {editing ? (<>
                                    <Checkbox
                                        checked={roleCategories[i].isExportable}
                                        onChange={(value) => {
                                            roleCategories[i].isExportable = roleCategories[i].isExportable ? false : true
                                            updateRole(role);
                                        }}
                                    />
                                </>) : (
                                    <div style={{ marginLeft: 30 }}>
                                        <b>Går att exportera</b>
                                        <p>{roleCategories[i].isExportable ? "Kan exportera" : "Kan inte exportera"}</p>
                                    </div>)
                                }



                                {category.countries.map((country: any, j: any) => {
                                    return (
                                        <div>
                                            <ListItem
                                                button
                                                className={classes.nested}
                                                style={{
                                                    width: "80%",
                                                    display: "inline-block",
                                                }}
                                            >
                                                <ListItemText
                                                    primary={countryMap.get(country.country)}
                                                />
                                            </ListItem>
                                            {editing ? (
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    style={{ float: "right", width: "20%" }}
                                                    onClick={() => {

                                                        setRemoveCountryIds({first: i, second: j})
                                                        setShowCountryDeleteConfirmation(true)
                                                    }}
                                                >
                                                    Remove country
                                                </Button>
                                            ) : null}

                                            <Collapse
                                                in={true}
                                                style={{ marginLeft: 50, marginTop: 10 }}
                                            >
                                                {editing ? (
                                                    <div>
                                                        <div>
                                                            <Checkbox
                                                                checked={country.video}
                                                                onChange={(value) => {
                                                                    roleCategories[i].countries[
                                                                        j
                                                                        ].video = !roleCategories[i]
                                                                        .countries[j].video;
                                                                    updateRole(role);
                                                                }}
                                                            />{" "}
                                                            Video
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={country.sound}
                                                                onChange={(value) => {
                                                                    roleCategories[i].countries[
                                                                        j
                                                                        ].sound = !roleCategories[i]
                                                                        .countries[j].sound;
                                                                    updateRole(role);
                                                                }}
                                                            />{" "}
                                                            Ljud
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={country.text}
                                                                onChange={(value) => {
                                                                    roleCategories[i].countries[
                                                                        j
                                                                        ].text = !roleCategories[i]
                                                                        .countries[j].text;
                                                                    updateRole(role);
                                                                }}
                                                            />{" "}
                                                            Text
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={country.overview}
                                                                onChange={(value) => {
                                                                    roleCategories[i].countries[
                                                                        j
                                                                        ].overview = !roleCategories[i]
                                                                        .countries[j].overview;
                                                                    updateRole(role);
                                                                }}
                                                            />{" "}
                                                            Overview
                                                        </div>
                                                        <div>
                                                            <Checkbox
                                                                checked={country.access}
                                                                onChange={(value) => {
                                                                    roleCategories[i].countries[
                                                                        j
                                                                        ].access = !roleCategories[i]
                                                                        .countries[j].access;
                                                                    updateRole(role);
                                                                }}
                                                            />{" "}
                                                            Access
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        {country.text ? <p>Text</p> : null}
                                                        {country.video ? <p>Video</p> : null}
                                                        {country.sound ? <p>Audio</p> : null}
                                                        {country.access ? <p>Access</p> : null}
                                                        {country.overview ? <p>Overview</p> : null}
                                                    </div>
                                                )}
                                            </Collapse>
                                        </div>
                                    );
                                })}

                                <div style={{ marginLeft: 20 }}>
                                    <Modal
                                        aria-labelledby="simple-modal-title"
                                        aria-describedby="simple-modal-description"
                                        open={addNewCountry}
                                        onClose={() => {
                                            setAddNewCountry(false);
                                        }}
                                    >
                                        <div style={modalStyle} className={classes.paper}>
                                            <div style={{ marginBottom: 10 }}>
                                                <Select
                                                    options={countries}
                                                    label={"Country"}
                                                    defaultValue={"sweden"}
                                                    value={newCountry}
                                                    onChange={(value) => {
                                                        setNewCountry(value.target.value);
                                                    }}
                                                />
                                            </div>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    saveNewCountry(i);
                                                }}
                                            >
                                                Add new country
                                            </Button>
                                        </div>
                                    </Modal>

                                    {editing ? (
                                        <Button
                                            style={{ marginTop: 20 }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                setAddNewCountry(true);
                                            }}
                                        >
                                            Add new country
                                        </Button>
                                    ) : null}
                                </div>
                            </List>
                        </Collapse>
                    </div>
                );
            })}
        </List>


        <ConfirmationModal show={showCategoryDeleteConfirmation}
                           onConfirmation={removeCategory}
                           toggleFunc={toggleCategoryDeleteModal}
        />
        <ConfirmationModal show={showCountryDeleteConfirmation}
                           onConfirmation={removeCountry}
                           toggleFunc={toggleCountryDeleteModal}
        />

    </>
}

