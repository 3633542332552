import React, { useEffect, useState  } from 'react';
import {Container, TextField, Button, FormControl} from "@material-ui/core";
import {Card, CardContent} from '@material-ui/core/';
import { getHeadCategories, createHeadCategory, getTopHeadCategories,updateHeadCategory, deleteHeadCategory } from './../../services/categoryService'
import { makeStyles } from '@material-ui/core/styles';
import Select from '../../components/forms/Select';
import ConfirmationModal from "../../components/layout/ConfirmationModal";

const useStyles = makeStyles({
  card: {
    minWidth: 275,
     marginBottom: 12,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
    defFormControl: {
        width: '100%',
    },
    space: {
        marginRight: 5,
    }
});


const AdminCategory : React.FC = () => {
    const classes = useStyles();
    const [categories, setcategories] = useState([]);
    const [loading, setLoading] = useState(false);
    const [categoryText, setCategoryText] = useState("");
    const [topHeadCategories, setTopHeadCategories] = useState([])
    const [topHeadOrder, setTopHeadOrder] = useState({})

    const [selectedTopHeadCat, setSelectedTopHeadCat] = useState("")
    const getCategoryRoutine = ()=> {
        setLoading(true)
        getHeadCategories().then((res:any) =>{
            setcategories(res)
            setLoading(false)

        })
    }

    const getTopHeadCategoriesR = () => {
        getTopHeadCategories().then((res:any) =>{
            setTopHeadCategories(res)
            let obj:any = {

            }
            res.map((r:any) => {
                obj[r._id] = r
            })

            setTopHeadOrder(obj)
        })
    }

    const addHeadCategory = () => {
        setLoading(true)
        createHeadCategory(categoryText, selectedTopHeadCat).then(() => {
            setCategoryText("")
            getCategoryRoutine()
        })
    }

    useEffect(() => {
        getCategoryRoutine()
        getTopHeadCategoriesR()
    }, [])



    return (
        <main className="page-admincountries">
            <Container>
                <h1>Dina kategorier</h1>
                {
                    categories.map((category:any) => {
                        return (
                            <Category category={category} topHeadCategories={topHeadCategories} reload={getCategoryRoutine} topHeadOrder={topHeadOrder}/>
                        )
                    })
                }
            </Container>
            <Container>
                <h2>Lägg till huvudkategori</h2>
                <div className="row">
                    <div className="col-md-3">
                        <FormControl className={classes.defFormControl}>
                            <TextField
                                value={categoryText}
                                onChange={(e) => setCategoryText(e.target.value)}
                                label={"Category"}
                            />
                        </FormControl>

                    </div>
                    <div className="col-md-3">
                        <FormControl className={classes.defFormControl}>
                            <Select
                                options={
                                    topHeadCategories.map((key: any) => ({

                                        value: key._id ,
                                        name: key.title,
                                    }))
                                }
                                onChange={(e) => {
                                    setSelectedTopHeadCat(e.target.value)
                                }}
                                value={selectedTopHeadCat}
                                label={"Top head category"}

                            />
                        </FormControl>

                    </div>
                </div>

                <Button color="primary" variant="contained"  onClick={addHeadCategory}>
                    Lägg till
                </Button>
              </Container>
        </main>
    )
}

const Category : React.FC<any> = (props) => {
    const [editing, setEditing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState(props.category.title)
    const [order, setOrder] = useState(props.category.order)
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)

    const [selectedTopHeadCat, setSelectedTopHeadCat] = useState(props.category.topHeadCategory)

    const classes = useStyles();

    const updateCategoryRoutine = () => {
     let head = props.category
     head.title = text
     head.topHeadCategory = selectedTopHeadCat
     head.order = Number(order)
     updateHeadCategory(head).then((res) => {
         setEditing(false)
     })
    }

    const removeCategory = () => {
        deleteHeadCategory(props.category._id).then((res) => {
            props.reload()
        })
    }

    const toggleDeleteModal = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation)
    }

    return (
        <Card className={classes.card}>
            <CardContent>
                {
                    editing ?
                    (<div>
                        <TextField value={text} onChange={(e) => setText(e.target.value)} />
                        <TextField value={order} onChange={(e) =>setOrder(e.target.value)} />

                        <Select
                        options={
                            props.topHeadCategories.map((key: any) => ({

                                value: key._id ,
                                name: key.title,
                            }))
                        }
                        onChange={(e) => {
                            setSelectedTopHeadCat(e.target.value)
                        }}
                        value={selectedTopHeadCat}
                        label={"Top head cat"}

                    />
                        <Button  onClick={() => {setEditing(false)}}>
                            Avbryt
                        </Button>
                        <Button  onClick={() => {updateCategoryRoutine()}}>
                            Uppdatera
                        </Button>
                    </div>)
                    :
                    (
                    <div>
                        <h3>{props.category.title}</h3>
                        <p>{props.topHeadOrder[props.category.topHeadCategory] ? props.topHeadOrder[props.category.topHeadCategory].title : null}</p>
                        <Button className={classes.space} variant="contained" onClick={() => setEditing(true)}>Edit</Button>
                        <Button variant="contained"  color="secondary" onClick={() => {
                            setShowDeleteConfirmation(true)
                        }}>Delete</Button>

                    </div>
                    )
                }
            </CardContent>

            <ConfirmationModal show={showDeleteConfirmation} onConfirmation={removeCategory} toggleFunc={toggleDeleteModal}/>
        </Card>
    )
}


export default AdminCategory;
