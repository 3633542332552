import React, {useState} from 'react';
import useDownloadLogFetch from "../components/Reports/downloadLogFetch";
import {Container} from "@material-ui/core";
import DataTable from 'react-data-table-component';
import useDownloadLogByUserFetch from "../components/Reports/downloadLogByUserFetch";


interface DownloadLog {}

const DownloadLog : React.FC = () => {
    const [reload, setReload] = useState({});
    const {loading, data} = useDownloadLogFetch(reload);
    const log2 = useDownloadLogByUserFetch(reload);

    const columns = [
        {
            name: 'Report name',
            selector: row => row.id,
        },
        {
            name: 'Downloads',
            selector: row => row.title,
        },
        {
            name: 'Last download',
            selector: row => row.year,
        },
    ];

    const data3 = data.map(row => {
        let newDate = 'null';
        if (row.createdAt) {
            newDate = new Intl.DateTimeFormat(
                'se-SE',
                {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).format(new Date(row.createdAt).getTime());
        }

        return {
            id: row._id,
            title: row.count,
            year: newDate
        }
    })


    const columns2 = [
        {
            name: 'Report name',
            selector: row => row.id,
        },
        {
            name: 'Username',
            selector: row => row.title,
        },
        {
            name: 'Company',
            selector: row => row.company,
        },
        {
            name: 'Download timestamp',
            selector: row => row.year,
        },
    ];

    const data4 = log2.data.map(row => {
        let newDate = 'null';
        if (row.createdAt) {
            newDate = new Intl.DateTimeFormat(
                'se-SE',
                {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                }).format(new Date(row.createdAt).getTime());
        }

        return {
            id: row.report_name,
            title: row.username,
            company: row.company,
            year: newDate
        }
    })


    const customStyles = {
        rows: {
            style: {
                minHeight: '72px', // override the row height
            },
        },
        headCells: {
            style: {
                fontWeight: 600,
                paddingLeft: '8px', // override the cell padding for head cells
                paddingRight: '8px',
                backgroundColor: "#D5C6CE",
                borderBottom: "1px solid black",
                fontSize: 14,

            },
        },
        cells: {
            style: {
                paddingLeft: '8px', // override the cell padding for data cells
                paddingRight: '8px',
            },
        },
    };

    return (
        <Container>
            <section className="page-admincategories" style={{marginTop: "100px"}}>
                <h1>Download log</h1>


                <div className="mt-5">
                    <DataTable
                        pagination={true}
                        columns={columns}
                        data={data3}
                        customStyles={customStyles}
                        paginationPerPage={25}
                        striped={true}
                        progressPending={loading}
                    />
                </div>

                <div className="mt-5">
                    <DataTable
                        pagination={true}
                        columns={columns2}
                        data={data4}
                        customStyles={customStyles}
                        paginationPerPage={25}
                        striped={true}
                        progressPending={log2.loading}
                    />
                </div>
            </section>
        </Container>


    )
}

export default DownloadLog;
