import { useEffect, useState } from "react";

const useReportFetch = (reload: object, user: any) => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    let token = localStorage.getItem('token')
    const url = process.env.REACT_APP_BACKEND_URL

    const fetchData = async (language = null) => {
        try {

            const response = fetch(`${url}/reports/${user.sub}`,  {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                },
            }).then((res:any) => {
                console.log('first', res)
                return res.json();
            }).then((a:any) => {
                setData(a)
                setLoading(false);
            });
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [reload]);

    return { loading, setLoading, data, setData, fetchData };
};

export default useReportFetch;
