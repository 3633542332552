import React, { useEffect, useState } from "react";
import {
  getRoles,
  saveRole,
  putRole,
} from "./../../services/roleService";
import { getCategories } from "./../../services/categoryService";
import { getCountries } from "./../../services/countryService";
import { Container, TextField, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import {Role} from "./Role";

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 275,
    marginBottom: 12,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },

  root: {
    width: "100%",
    maxWidth: 360,
  },
  nested: {
    paddingLeft: 30,
  },
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    display: "block",
  },
}));

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const AdminCountries: React.FC = () => {
  const classes = useStyles();
  const [editing, setEditing] = useState(false);

  const [roles, setRoles] = useState([]);
  const [categories, setCategories] = useState([]);
  const [countries, setCountries] = useState([]);
  const [newRole, setNewRole] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [modalStyle] = React.useState(getModalStyle);

  function updateRole(i: any, role: any) {
    let newR = JSON.parse(JSON.stringify(roles));
    newR[i] = role;
    setRoles(newR);
  }

  useEffect(() => {
    getRoles().then((res) => {
      setRoles(res);
    });

    getCategories().then((res) => {
      setCategories(
        res.map((cat: any) => {
          return { value: cat._id, name: cat.title };
        })
      );
    });

    getCountries().then((res) => {
      setCountries(
        res.map((cat: any) => {
          return { value: cat._id, name: cat.title };
        })
      );
    });
  }, []);

  function createNewRole() {
    saveRole({
      title: newRole,
      categories: [],
    })
      .then((res) => {
        return getRoles();
      })
      .then((roles) => {
        setRoles(roles);
        setNewRole("");
        setIsOpen(false);
      });
  }

  return (
    <main className="page-adminroles">
      <Container style={{ marginTop: 100 }}>
        <div>
          <h1 style={{ display: "inline-block" }}>Roles</h1>
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 20, float: "right", marginBottom: 20 }}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Add new role
          </Button>
        </div>
        {roles.map((role: any, i: any) => {
          return (
            <Role
              role={role}
              reloadRoles={() => {
                getRoles().then((res) => {
                  setRoles(res);
                });
              }}
              save={(title: string, updated:string, premiumUpdated:string, country:string) => {
                console.log(role);
                let newR = JSON.parse(JSON.stringify(role));
                newR.title = title;
                newR.updated = updated;
                newR.premiumUpdated = premiumUpdated;
                newR.countrySet = country;
                return putRole(newR);
              }}
              countries={countries}
              categories={categories}
              saveRole={() => {}}
              updateRole={(role: any) => {
                updateRole(i, role);
              }}
            />
          );
        })}

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
          }}
        >
          <div style={modalStyle} className={classes.paper}>
            <TextField
              style={{ display: "block", width: "100%" }}
              placeholder="Name"
              value={newRole}
              onChange={(e) => setNewRole(e.target.value)}
            />
            <Button
              style={{ marginTop: 10 }}
              variant="contained"
              color="primary"
              onClick={() => {
                createNewRole();
              }}
            >
              Add new role
            </Button>
          </div>
        </Modal>
      </Container>
    </main>
  );
};



export default AdminCountries;
