import Modal from "@material-ui/core/Modal";
import {Button, Container, FormControl, Input, InputLabel, MenuItem, Select} from "@material-ui/core";
import React, {useState} from "react";
import {getModalStyle, useStyles} from "../../pages/AdminCategories/Styles";
import {createReports} from "../../services/categoryService";
import {Alert} from "@material-ui/lab";
import {useAuth0} from "../../react-auth0-spa";


export const CreateModal : React.FC<{isOpen: boolean, setIsOpen: any, setReload: any, reportGroups: any[]}> = ({isOpen, setIsOpen, setReload, reportGroups}) => {
    const [modalStyle] = React.useState(getModalStyle);
    const classes = useStyles();

    const [reportName, setReportName] = useState('');
    const [publishingDate, setPublishingDate] = useState('');
    const [period, setPeriod] = useState('');
    const [reportGroup, setReportGroup] = useState('');
    const [error, setError] = useState('');
    const [file, setFile] = useState(null);
    const [filename, setFilename] = useState('');
    const [currentReportGroup, setCurrentReportGroup] = useState('');

    const {user} = useAuth0();

    const submitReport = () => {
        setError('');
        let reportData = new FormData()
        if (file && file[0]) {
            reportData.append('file', file[0])
        }
        reportData.append('report_name', reportName)
        reportData.append('report_group', currentReportGroup)
        reportData.append('publishing_date', publishingDate)
        reportData.append('period', period)
        reportData.append('file_name', filename)
        reportData.append('file_path', filename)
        reportData.append('user_id', user.sub)

        createReports(reportData).then(async (res) => {
            setReload({});

            if (res.httpStatusCode === 422) {
                setError(res.details[0].message)
                return false;
            }

            if (res.errmsg) {
                setError("Validation error (probably duplicate)")
                return false;
            }

            if (res.errors && Object.keys(res.errors).length > 0) {
                setError(res.message)
                return false;
            }

            setReportName('');
            setPublishingDate('');
            setPeriod('');
            setReportGroup('');
            setError('');
            setFile(null);
            setFilename('');

            setIsOpen(false);
        }).catch(e => {
            console.log('e' , e)
        });
    }

    const handleChange = (event: any) => {
        setCurrentReportGroup(event.target.value as string);
    };

    const reportGroupOptions = reportGroups.map((reportGroup: any) => {
        return <MenuItem key={reportGroup.name} value={reportGroup.name}>{reportGroup.name}</MenuItem>
    })


    return <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isOpen}
        onClose={() => {
            setIsOpen(false)
        }}

    >
        <div style={modalStyle} className={classes.paper}>
            <Container>
                {error !== '' ?
                    <Alert className="mb-3" severity="error">{error}</Alert> : '' }

                <h3>Create report</h3>
                <FormControl className={classes.defFormControl}>
                    <InputLabel>Report name</InputLabel>
                    <Input value={reportName} onChange={(e) => setReportName(e.target.value)}/>
                </FormControl>
                <FormControl className={classes.defFormControl}>
                    <InputLabel>Publishing date</InputLabel>
                    <Input value={publishingDate} onChange={(e) => setPublishingDate(e.target.value)}/>
                </FormControl>

                <FormControl className={classes.defFormControl}>
                    <InputLabel>Period</InputLabel>
                    <Input value={period} onChange={(e) => setPeriod(e.target.value)}/>
                </FormControl>


                <FormControl className={classes.defFormControl}>
                    <InputLabel>Report group</InputLabel>
                    {/*<Input value={reportGroup} onChange={(e) => setReportGroup(e.target.value)}/>*/}
                    <Select name="report-group" onChange={handleChange} value={currentReportGroup} label={'report group'} >
                        {reportGroupOptions}
                    </Select>
                </FormControl>


                <div className="div-space center mb-2">
                    <input
                        accept="application/vnd.sealed.xls/*"
                        className="hide"
                        id="contained-button-file"
                        multiple
                        type="file"
                        required={true}
                        onChange={(e) => {
                            if (e.target.files && e.target.files[0]) {
                                const newFileName = e.target.files[0].name ?? '';
                                setFilename(newFileName);
                                setFile(e.target.files ?? '');
                            }
                        }}
                    />
                    <label htmlFor="contained-button-file" className="center ">
                        <Button variant="contained" component="span">
                            Upload
                        </Button>
                    </label>
                    <p className="center p-2">{filename}</p>
                </div>

                <div>
                    <Button color="primary" variant="contained" onClick={submitReport}>
                        Add category
                    </Button>
                </div>

            </Container>

        </div>
    </Modal>

}


