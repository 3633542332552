import React, { useEffect, useState  } from 'react';
import { Container, TextField, Button } from "@material-ui/core";
import {Card, CardContent} from '@material-ui/core/';

import {createStream,getStreamsAdmin,updateStream, deleteStream } from './../../services/streamingService'
import {getRoles } from './../../services/roleService'
import Select from '../../components/forms/Select';


import { makeStyles } from '@material-ui/core/styles';
import { filter } from 'lodash';

const useStyles = makeStyles({

});


const Streams : React.FC = () => {
    const classes = useStyles();
    const [streams, setStreams] = useState([]);
    const [roles, setRoles] = useState([]);
    const [filterRole, setFilterRole] = useState();

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [url, setUrl] = useState("");
    const [date, setDate] = useState("");
    const [createRole, setCreateRole] = useState();



    const getStreams = ()=> {
        getStreamsAdmin().then((res) => {
            setStreams(res)
        })
    }

    const addStream = () => {
        createStream(title, description, url, [createRole],date).then((res) => {
            getStreams()
        })
    }

 

    useEffect(() => {
        getStreams()
        getRoles().then((roles) => {
            setRoles(roles)
        })
    }, [])



    return (
        <main className="page-admincountries">
            <Container>
                <h1>Dina streams</h1>
                <Select 
                        options={
                            roles.map((key: any) => ({

                                value: key._id ,
                                name: key.title,
                            }))
                        }
                        onChange={(e) => {
                            setFilterRole(e.target.value)
                        }}
                        value={filterRole}
                        label={"Role"}
                        
                    />
                {
                    streams.map((stream:any) => {
                        if(filterRole && stream.roles[0] !== filterRole){
                            return <></>
                        }
                        return (
                           <Stream stream={stream} roles={roles}  update={() => {getStreams()}} />
                        )
                    })
                }
            </Container>
            <Container style={{marginTop:20}}>
                <h2>lägg till en stream</h2>
                <p>Titel</p>
                <TextField value={title} onChange={(e) => setTitle(e.target.value )} />
                <p>Beskrivning</p>
                <TextField value={description} onChange={(e) => setDescription(e.target.value)} />
                <p>URL</p>
                <TextField value={url} onChange={(e) => setUrl(e.target.value)} />
                <p>Publiceringsdatum</p>
                <TextField value={date} onChange={(e) => setDate(e.target.value)} />
                <Select 
                        options={
                            roles.map((key: any) => ({

                                value: key._id ,
                                name: key.title,
                            }))
                        }
                        onChange={(e) => {
                            setCreateRole(e.target.value)
                        }}
                        value={createRole}
                        label={"Role"}
                        
                    />

                <Button  onClick={addStream}>
                    Lägg till stream
                </Button>
              </Container>
        </main>
    )
}

const Stream : React.FC<any> = (props) => {
    const [editing, setEditing] = useState(false)
    const [title, setTitle] = useState(props.stream.title);
    const [description, setDescription] = useState(props.stream.description);
    const [date, setDate] = useState(props.stream.date);

    const [url, setUrl] = useState(props.stream.URL);
    const [role, setRole] = useState(props.stream.roles[0])
    const classes = useStyles();

    const updateStreamRoutine = () => {
        let stream = props.stream
        stream.title = title;
        stream.date = date
        stream.description = description;
        stream.URL = url
        stream.roles = [role]
        updateStream(stream).then((res) => {
            setEditing(false)
        })
    }

    const deleteStreamRoutine = () => {
        setEditing(false)
        deleteStream(props.stream._id).then(() => {
            props.update()
        })
    }

    return (
        <Card >
            <CardContent>
                {
                    editing ? 
                    (<div>
                        <p>Titel</p>
                        <TextField value={title} onChange={(e) => setTitle(e.target.value)} /> <br />
                        <p>Beskrivning</p>
                        <TextField value={description} onChange={(e) => setDescription(e.target.value)} /> <br />
                        <p>Url</p>
                        <TextField value={url} onChange={(e) => setUrl(e.target.value)} /><br />
                        <p>Datum</p>
                        <TextField value={date} onChange={(e) => setDate(e.target.value)} /><br />
                        <Select 
                            options={
                                props.roles.map((key: any) => ({
                                    value: key._id ,
                                    name: key.title,
                                }))
                            }
                            onChange={(e) => {
                                setRole(e.target.value)
                            }}
                            value={role}
                            label={"Role"}
                        
                        /> <br />
                        <Button  onClick={() => {setEditing(false)}}>
                            Avbryt
                        </Button><br />
                        <Button  onClick={() => {updateStreamRoutine()}}>
                            Uppdatera
                        </Button><br />
                        <Button  onClick={() => {deleteStreamRoutine()}}>
                            Ta bort
                        </Button>
                    </div>) 
                    : 
                    (
                    <div>
                        <h3>{props.stream.title}</h3> 
                        <Button onClick={() => setEditing(true)}>Edit</Button>
                    </div>
                    )
                }
            </CardContent>
        </Card>
    )
}


export default Streams;