import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import { Container, Button } from '@material-ui/core';
import Streaming from "./pages/AdminCategories/Streaming";
import AdminCategories from "./pages/AdminCategories/AdminCategories";
import Countries from "./pages/AdminCategories/Countries";
import Headcategories from "./pages/AdminCategories/HeadCategories";
import TopHeadcategories from "./pages/AdminCategories/TopHeadCategories";
import Bulk from './pages/AdminCategories/Bulk'
import Roles from "./pages/AdminCategories/Roles";
import FrontPage, { FrontPageHeader } from "./pages/FrontPage/FrontPage";
import Me from './pages/Me/Me'
import StreamingOverview from './pages/Streaming/Overview'
import StreamingSingle from './pages/Streaming/Single'
import FAQ from './pages/faq'
import Links from "./pages/links";
import Reports from "./pages/Reports/reports";
import DownloadLog from "./pages/downloadlog";
import {GraphsLauncher} from "./pages/Graphs/GraphsLauncher";

const RouterWithRoutes : React.FC = (props) => {
    return (
        <Router>
          <Switch>
            <Route exact path="/">
              <FrontPageHeader />
              <FrontPage />
            </Route>
            <Route exact path="/faq">
              <FrontPageHeader />
              <FAQ />
            </Route>
            <Route exact path="/data">
              <FrontPageHeader />
              <GraphsLauncher />
            </Route>
            <Route exact path="/me">
              <FrontPageHeader />
              <Me />
            </Route>
            <Route exact path="/admin/categories">
              <FrontPageHeader />
              <AdminCategories />
            </Route>
            <Route exact path="/admin/categories/:id">
              <FrontPageHeader />
              <AdminCategories />
            </Route>
             <Route exact path="/admin/countries">
               <FrontPageHeader />
              <Countries />
            </Route>
            <Route exact path="/admin/headcategories">
               <FrontPageHeader />
              <Headcategories />
            </Route>
            <Route exact path="/admin/topheadcategories">
               <FrontPageHeader />
              <TopHeadcategories />
            </Route>
            <Route exact path="/admin/roles">
              <FrontPageHeader />
              <Roles />
            </Route>
            <Route exact path="/admin/reports">
              <FrontPageHeader />
              <Reports />
            </Route>
            <Route exact path="/streaming">
              <FrontPageHeader />
              <StreamingOverview />
            </Route>
            <Route exact path="/stream/:id">
              <FrontPageHeader />
              <StreamingSingle />
            </Route>
            <Route exact path="/admin/streaming">
              <FrontPageHeader />
              <Streaming />
            </Route>
            <Route exact path="/admin/bulk">
              <FrontPageHeader />
              <Bulk />
            </Route>
            <Route exact path="/admin/links">
              <FrontPageHeader />
              <Links />
            </Route>
            <Route exact path="/admin/download-log">
              <FrontPageHeader />
              <DownloadLog />
            </Route>
            <Route path="*">
              <FrontPageHeader />
              <Container maxWidth="xs">
                <h1>404</h1>
                <p>Åh nej, sidan kunde inte hittas. Sidan kan ha funnits och tagits bort, eller så kan du ha klickat på en felaktig länk</p>
                <Button variant="outlined" color="primary">
                  <Link to={"/"} style={{textDecoration: 'none'}}>
                    Gå hem
                  </Link>
                </Button>
              </Container>
            </Route>
          </Switch>
        </Router>
    )
}

export default RouterWithRoutes;
